import React, {useEffect, useState} from "react";

import ArgonBox from "../../../components/ArgonBox";
import ArgonTypography from "../../../components/ArgonTypography";

import {ExpandMore, Info, Visibility, VisibilityOff} from "@mui/icons-material";
import Collapse from "@mui/material/Collapse";
import CardContent from "@mui/material/CardContent";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {Grid, Card, CardHeader, InputAdornment, tooltipClasses, CircularProgress} from "@mui/material";

// Images
import logoJira from "assets/images/small-logos/logo-jira.svg";
import ArgonInput from "../../../components/ArgonInput";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import {styled} from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import ErrorIcon from "@mui/icons-material/Error";
import ArgonButton from "../../../components/ArgonButton";
import {LoadingButton} from "@mui/lab";
import {connectToJira} from "../../../api/BackendApi/Account";
import ArgonSelect from "../../../components/ArgonSelect";
import {
	attachReportJira,
	createIssueJira,
	getListIssueType,
	getTickets,
} from "../../../api/BackendApi/microservices/ManagementTool";
import {enqueueSnackbar} from "notistack";
import {updateInstanceDefect} from "../../../api/BackendApi/TestInstances";
import DataTable from "../../../components/DataTable";
import Link from "@mui/material/Link";

export function JiraTool({userIntegrations, setManagementSend, jira, expandedDialog, module, otherInfo}) {

	const [check, setCheck] = useState(false);
	const [checkError, setCheckError] = useState(false);
	const [checkSuccess, setCheckSuccess] = useState(false);

	const [expanded, setExpanded] = useState(expandedDialog);
	const [showPassword, setShowPassword] = React.useState(false);

	const handleClickShowPassword = () => setShowPassword((show) => !show);
	const handleMouseDownPassword = (event) => {event.preventDefault();};

	const [url, setUrl] = useState("");
	const [email, setEmail] = useState("");
	const [token, setToken] = useState("");
	const [projectDefault, setProjectDefault] = useState(null);
	const [projectSelectedJira, setProjectSelectedJira] = useState(null);

	const [titleIssue, setTitleIssue] = useState("");
	const [descriptionIssue, setDescriptionIssue] = useState(null);

	const [ticketJira, setTicketJira] = useState(null);

	useEffect(() => {

		if(otherInfo !== null){
			setTitleIssue("[BUG] " +otherInfo.testNameSelected);
		}

		for (let integration of userIntegrations) {
			if (integration.integration === "Jira"){
				setUrl(integration.url);
				setEmail(integration.email);
				setToken(integration.token);
				if(integration.project && integration.project !== ""){
					setProjectDefault(integration.project);
				}
			}
		}
	}, []);

	const loading = () => {
		return (<ArgonBox mt={5}
					style={{ display: "flex", justifyContent: "center", alignItems: "center"}}>
					<CircularProgress size={64} />
				</ArgonBox>);
	};

	const handleExpandClick = () => {
		setExpanded(!expanded);
	};

	const handleSelectProjectJira = async (e) => {
		if (e !== null) {
			setProjectSelectedJira(e.value)
			if(module === "Generative"){

				setTicketJira(null)

				let infoToGetUserStoryOnJira = {
					"domain": url,
					"user": email,
					"token": token,
					"project_name": e.label,
					"issue_name": "Story",
					"status": "Done" //ESCLUSO
				}

				try{
					let get_user_story = await getTickets(infoToGetUserStoryOnJira)

					if(get_user_story.data.total > 0){
						setTicketJira(get_user_story.data.issues)
					}else{
						setTicketJira([])
					}
				}catch (e){
					setTicketJira([])

					console.log(e)
					enqueueSnackbar("Error!", {variant: "error"});
				}
			}
		} else
			setProjectSelectedJira(null)
	};

	const sendIssueToJira = async () => {
		try{
			setCheck(true)

			let infoToGetIssueTypeOnJira = {
				"domain": url,
				"user": email,
				"token": token,
				"project": projectSelectedJira
			}

			let issue_type_list = await getListIssueType(infoToGetIssueTypeOnJira)
			let issue_type_id = null

			for(let issue_type of issue_type_list.data){
				if(issue_type.name.includes("Bug") || issue_type.name.includes("Defect")){
					issue_type_id = issue_type.id
				}
			}

			let infoToCreateIssueOnJira = {
				"domain": url,
				"user": email,
				"token": token,
				"project": projectSelectedJira,
				"title": titleIssue,
				"description": descriptionIssue,
				"issue_type_id": issue_type_id
			}

			let response = await createIssueJira(infoToCreateIssueOnJira)

			let issue_id = response.data.id
			let issue_key = response.data.key

			if(response.status === 200 || response.status === 201) {

				try{
					await updateInstanceDefect(otherInfo.testInstanceSelected,url + "browse/" + issue_key)
				}catch (err) {
					console.log("Error during save defect into the test istance. Error: " + err)
				}

				if(otherInfo.reportSelected!==null){

					try{
						const formData = new FormData();

						formData.append("files", otherInfo.reportSelected);
						formData.append("domain", url);
						formData.append("user", email);
						formData.append("token", token);
						formData.append("project", projectSelectedJira);
						formData.append("issue_id", issue_id);

						let response = await attachReportJira(formData)

						if(response.status === 200 || response.status === 201) {
							enqueueSnackbar('Issue created successfully on Jira!',{variant:'success'})
						}else {
							enqueueSnackbar('Issue created successfully without report on Jira!',{variant:'warning'})
						}
					}catch (err) {
						enqueueSnackbar('Issue created successfully without report on Jira!',{variant:'warning'})
					}
				}else{
					enqueueSnackbar('Issue created successfully without report on Jira!',{variant:'warning'})
				}
			}else {
				enqueueSnackbar('Error during creating issue on Jira!',{variant:'error'})
			}
		}catch (err) {
			console.log(err)
			enqueueSnackbar('Error during creating issue on Jira!',{variant:'error'})
		}finally {
			setCheck(false)
			setManagementSend(false)
		}
	}

	const handleChangeValue = (val,name) => {
		switch (name) {
			case "url":
				setUrl(val);
				break;
			case "email":
				setEmail(val);
				break;
			case "token":
				setToken(val);
				break;
			default:
				break;
		}
	}

	const handleConnectIntegration = async () => {
		try{
			setCheck(true)
			setCheckError(false)
			const response = await connectToJira(url,email,token);
			setCheck(false)
			if (response.status === 200) {
				if(projectDefault!==null){
					jira.setProjectsJira(response.data.values.filter(project => project.key === projectDefault))
				}else{
					jira.setProjectsJira(response.data.values)
				}
				setCheckSuccess(true)
				jira.setConnectedJira(true)
			}else{
				setCheckError(true)
				setCheckSuccess(false)
			}
		}catch (err) {
			setCheck(false)
			setCheckError(true)
			setCheckSuccess(false)

			console.log(err)
		}
	}

	const NoMaxWidthTooltip = styled(({ className, ...props }) => (
		<Tooltip {...props} classes={{ popper: className }} />
	))({
		[`& .${tooltipClasses.tooltip}`]: {
			maxWidth: 'none',
		},
	});

	return (
		<Card>
			<CardHeader
				action={
					<ExpandMore
						expand={expanded}
						onClick={handleExpandClick}
						aria-expanded={expanded}
						aria-label="show more"
					>
						<ExpandMoreIcon />
					</ExpandMore>
				}
				title={
					<Grid container justifyContent="center" alignItems="center">
						<Grid item >
							<ArgonBox>
								<img src={logoJira} style={{width: "3vw", height: "3vw"}} alt="logoJira"/>
							</ArgonBox>
						</Grid>
						<Grid item >
							<ArgonBox>
								<ArgonTypography component="label" variant="h5" fontWeight="bold">
									Jira
								</ArgonTypography>
							</ArgonBox>
						</Grid>
					</Grid>
				}
			/>
			<Collapse in={expanded} timeout="auto" unmountOnExit>
					{
						jira.connectedJira ?
							<CardContent>
								<ArgonBox mb={projectSelectedJira === null ? 25 : 3}>
									<ArgonSelect
										size={"large"}
										placeholder={"Select Project"}
										onChange={(e) => handleSelectProjectJira(e)}
										options={jira.projectsJira.map((project) => {
											return ({
												"label": project.name,
												"value": project.id
											})
										})}
									/>
								</ArgonBox>
								{module === "TestResult" ?
									<>
										{projectSelectedJira!==null ?
											<>
												<ArgonBox>
													<Grid container>
														<Grid item xs={12} md={12} sx={{textAlign: "center"}}>
															<ArgonTypography component="label" variant="h5" fontWeight="bold">
																CREATE ISSUE
															</ArgonTypography>
														</Grid>
														<Grid item xs={12} md={12} mb={3}>
															<ArgonTypography fontSize={14}>Title</ArgonTypography>
															<ArgonBox>
																<ArgonInput
																	placeholder="[BUG] Issue Title"
																	size="medium"
																	value={titleIssue==null ? "" : titleIssue}
																	onChange={(e) => setTitleIssue(e.target.value !== "" ? e.target.value : null)}
																/>
															</ArgonBox>
														</Grid>
														<Grid item xs={12} md={12} mb={3}>
															<ArgonTypography fontSize={14}>Description</ArgonTypography>
															<ArgonBox>
																<ArgonInput
																	multiline rows={5}
																	placeholder="Insert Description"
																	size="medium"
																	value={descriptionIssue==null ? "" : descriptionIssue}
																	onChange={(e) => setDescriptionIssue(e.target.value !== "" ? e.target.value : null)}
																/>
															</ArgonBox>
														</Grid>
														<Grid item mt={2} xs={12} md={12} sx={{textAlign: "center"}}>
															{!check ?
																<ArgonButton
																	variant="contained"
																	color="primary"
																	size="large"
																	onClick={sendIssueToJira}
																	disabled={titleIssue==null || descriptionIssue==null}
																>
																	Create Issue
																</ArgonButton>
																:
																<LoadingButton color="primary" variant="contained" size="large" loading>Create Issue</LoadingButton>
															}
														</Grid>
													</Grid>
												</ArgonBox>
											</>
											:
											<></>
										}
									</>
									:
									<>
										{projectSelectedJira!==null ?
											<>
												<ArgonBox>
													<Grid container>
														<Grid item xs={12} md={12} sx={{textAlign: "center"}}>
															<ArgonTypography component="label" variant="h5" fontWeight="bold">
																USER STORY
															</ArgonTypography>
														</Grid>
														<Grid item xs={12} md={12} mb={3}>
															{ticketJira!==null ?
																<>
																{ticketJira.length > 0 ?
																	<>
																		<Grid item xs={12} md={12} sx={{textAlign: "center"}}>
																			<DataTable
																				canSearch={true}
																				entriesPerPage={false}
																				enableSelection={true}
																				onSelectionChange={(rows) => {

																					jira.jiraFiles.current = rows.filter(v => v != undefined).map(r => r?.values);

																					if(jira.jiraFiles.current.length > 0){
																						setManagementSend(true);
																					}else {
																						setManagementSend(false);
																					}
																				}}
																				table={{
																					columns: [
																						{ Header: "KEY", accessor: "ticket_key", width: "2vw" },
																						{ Header: "NAME", accessor: "ticket_name", maxWidth: "40vw" },
																					],
																					rows: ticketJira?.map((ticket) => {

																						return {
																							"ticket_id": ticket.id,
																							"ticket_key": <Link href={`${url}browse/${ticket.key}`} target="_blank" underline="always">{ticket.key}</Link>,
																							"ticket_name": ticket.fields.summary,
																							"ticket_description": ticket.fields.description && ticket.fields.description.content?.flatMap(item => item.content)
																								.filter(contentItem => contentItem && contentItem.text)
																								.map(contentItem => contentItem.text)
																								.join(" ")
																						}
																					})
																				}}
																			/>
																		</Grid>

																	</>
																	:
																	<>
																		<Grid item xs={12} md={12} mt={5} sx={{textAlign: "center"}}>
																			<ArgonTypography component="label" variant="h6" fontWeight="bold">
																				NO DATA
																			</ArgonTypography>
																		</Grid>
																	</>
																}
																</>
																:
																loading()
															}
														</Grid>
													</Grid>
												</ArgonBox>
											</>
											:
											<></>
										}
									</>
								}
							</CardContent>
							:
							<CardContent>
								<ArgonBox
									bgColor={"grey-100"}
									borderRadius="lg"
									display="flex"
									justifyContent="space-between"
									alignItems={{ xs: "flex-start", sm: "center" }}
									flexDirection={{ xs: "column", sm: "row" }}
									my={3}
									py={1}
									pl={{ xs: 1, sm: 2 }}
									pr={1}
									mt={0}
								>
									<ArgonTypography variant="button" fontWeight="medium" color="text">
										Url
									</ArgonTypography>
									<ArgonBox width={{ xs: "100%", sm: "50%", md: "50%" }} mt={{ xs: 1, sm: 0 }}>
										<ArgonInput
											type="url"
											size="medium"
											placeholder="https://<YOUR_HOST>/"
											value={url}
											onChange={(e) => {handleChangeValue(e.target.value!=="" ? e.target.value : "","url")}}/>
									</ArgonBox>
								</ArgonBox>
								<ArgonBox
									bgColor={"grey-100"}
									borderRadius="lg"
									display="flex"
									justifyContent="space-between"
									alignItems={{ xs: "flex-start", sm: "center" }}
									flexDirection={{ xs: "column", sm: "row" }}
									my={3}
									py={1}
									pl={{ xs: 1, sm: 2 }}
									pr={1}
									mt={0}
								>
									<ArgonTypography variant="button" fontWeight="medium" color="text">
										email
									</ArgonTypography>
									<ArgonBox width={{ xs: "100%", sm: "50%", md: "50%" }} mt={{ xs: 1, sm: 0 }}>
										<ArgonInput
											type="email"
											size="medium"
											placeholder="aLhXH@example.com"
											value={email}
											onChange={(e) => {handleChangeValue(e.target.value!=="" ? e.target.value : "","email")}}/>
									</ArgonBox>
								</ArgonBox>
								<ArgonBox
									bgColor={"grey-100"}
									borderRadius="lg"
									display="flex"
									justifyContent="space-between"
									alignItems={{ xs: "flex-start", sm: "center" }}
									flexDirection={{ xs: "column", sm: "row" }}
									my={3}
									py={1}
									pl={{ xs: 1, sm: 2 }}
									pr={1}
								>
									<ArgonTypography variant="button" fontWeight="medium" color="text">
										Token
										<NoMaxWidthTooltip title="https://confluence.atlassian.com/enterprise/using-personal-access-tokens-1026032365.html" placement="top">
											<Icon>
												<Info></Info>
											</Icon>
										</NoMaxWidthTooltip >
									</ArgonTypography>
									<ArgonBox width={{ xs: "100%", sm: "50%", md: "50%" }} mt={{ xs: 1, sm: 0 }}>
										<ArgonInput
											size="medium"
											type={showPassword ? 'text' : 'password'}
											placeholder="Add your Token"
											autoComplete="off"
											value={token}
											endAdornment={
												<InputAdornment position="end">
													<IconButton
														aria-label="toggle password visibility"
														onClick={handleClickShowPassword}
														onMouseDown={handleMouseDownPassword}
														edge="end"
													>
														{showPassword ? <VisibilityOff /> : <Visibility />}
													</IconButton>
												</InputAdornment>
											}
											onChange={(e) => {handleChangeValue(e.target.value!=="" ? e.target.value : "","token")}}/>
									</ArgonBox>
								</ArgonBox>
								{!check ?
									<ArgonButton fullWidth variant="gradient" color="primary" sx={{ mr: 2 }} size="medium"
												 onClick={handleConnectIntegration} disabled={url==="" || email==="" ||token===""}>
										Connect
									</ArgonButton>
									:
									<LoadingButton fullWidth color="primary" loading variant="contained"  sx={{ mr: 2 }}  size="medium">Connect</LoadingButton>
								}
								{checkError &&
									<>
										{checkSuccess ?

											<></>
											:
											<ArgonTypography variant="button" fontWeight="light"  sx={{ mr: 2, color: 'red' }} color="text">
												<ErrorIcon fontSize="inherit"/> Connection Refused!
											</ArgonTypography>
										}
									</>
								}
							</CardContent>
					}
			</Collapse>
		</Card>
	);
} 


