import { forwardRef, useState } from "react";
import { Navigate } from "react-router-dom";
import { styled } from '@mui/system';
import Grid from "@mui/material/Grid";
import useAuth from "utils/useAuth";

import Dialog from '@mui/material/Dialog';

import { Fade } from "@mui/material";
import ArgonBox from "components/ArgonBox";

import Footer from "components/Footer";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";

import Card from "@mui/material/Card";
import Header from "components/Header";
import LogsView from "./components/LogsView";
import ProgressTable from "./components/ProgressTable";

import MuiToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

import ArgonTypography from "components/ArgonTypography";

function ExecutionProgress() {

  const isLoggedIn = useAuth().ensureLoggedIn();
  if (!isLoggedIn) {
    return <Navigate replace to="/sign-in" />;
  }

  const [formats, setFormats] = useState(() => ['running', 'completed', 'crashed', 'cancelled', 'aborted', 'pending']);

  const handleFormat = (
    event,
    newFormats,
  ) => {
    setFormats(newFormats);
  };

  const [openedExecLogs, setOpenedExecLogs] = useState(null);

  const handleExecLogsClose = () => {
    setOpenedExecLogs(null);
  }

  const handleExecLogsOpen = (execution) => {
    setOpenedExecLogs(execution);
  }

  const Transition = forwardRef(function Transition(props, ref) {
    return <Fade ref={ref} {...props} />;
  });

  const ToggleButton = styled(MuiToggleButton, {
    shouldForwardProp: (prop) => prop !== "selectedColor",
  })(({ selectedColor, selected }) => ({
    "&.Mui-selected, &.MuiToggleButtonGroup-grouped:hover": {
      backgroundColor: selectedColor,
      WebkitFilter: "opacity(100%)",
      WebkitTransition: "0.7s",
      MozTransition: "0.7s",
      transition: "0.7s",
    },
    "&.MuiToggleButtonGroup-grouped:not(.Mui-selected)": {
      backgroundColor: selected ? selectedColor : "gray",
      WebkitTransition: "0.7s",
      MozTransition: "0.7s",
      transition: "0.7s"
    }
  }));

  return (
    <DashboardLayout>
      <Header />
      {openedExecLogs != null && <Dialog
        open={openedExecLogs != null}
        onClose={handleExecLogsClose}
        fullWidth
        maxWidth={false}
        TransitionComponent={Transition}
        transitionDuration={300}
        keepMounted
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ marginLeft: "6%", marginRight: "6%" }}
      >
        <LogsView closeView={handleExecLogsClose} execId={openedExecLogs?.id} />
      </Dialog>}

      <ArgonBox mt={5} mb={3}>
        <Card>
          <ToggleButtonGroup
            value={formats}
            onChange={handleFormat}
            aria-label="status filter"
            sx={{
              display: "grid",
              gridTemplateColumns: "auto auto auto auto auto auto",
              gridGap: "10px",
              padding: "10px",
            }}
          >
            <ToggleButton value="pending" aria-label="pending" selectedColor="#ffb74d" sx={{"borderRadius": "10px !important"}}>
              <ArgonTypography color="white" mr={2}>Pending</ArgonTypography>
              <AccessTimeIcon color="white" fontSize="large"></AccessTimeIcon>
            </ToggleButton>
            <ToggleButton value="running" aria-label="running" selectedColor="#26a69a" sx={{"borderRadius": "10px !important"}}>
              <ArgonTypography color="white" mr={2}>Running</ArgonTypography>
              <AccessTimeIcon color="white" fontSize="large"></AccessTimeIcon>
            </ToggleButton>
            <ToggleButton value="completed" aria-label="completed" selectedColor="#4caf50" sx={{"borderRadius": "10px !important"}}>
              <ArgonTypography color="white" mr={2}>Completed</ArgonTypography>
              <AccessTimeIcon color="white" fontSize="large"></AccessTimeIcon>
            </ToggleButton>
            <ToggleButton value="crashed" aria-label="crashed" selectedColor="#ef5350" sx={{"borderRadius": "10px !important"}}>
              <ArgonTypography color="white" mr={2}>Crashed</ArgonTypography>
              <AccessTimeIcon color="white" fontSize="large"></AccessTimeIcon>
            </ToggleButton>
            <ToggleButton value="cancelled" aria-label="cancelled" selectedColor="#fb8c00" sx={{"borderRadius": "10px !important"}}>
              <ArgonTypography color="white" mr={2}>Cancelled</ArgonTypography>
              <AccessTimeIcon color="white" fontSize="large"></AccessTimeIcon>
            </ToggleButton>
            <ToggleButton value="aborted" aria-label="aborted" selectedColor="#78909c" sx={{"borderRadius": "10px !important"}}>
              <ArgonTypography color="white" mr={2}>Aborted</ArgonTypography>
              <AccessTimeIcon color="white" fontSize="large"></AccessTimeIcon>
            </ToggleButton>
          </ToggleButtonGroup>
        </Card>
        <Grid container mt={5}>
          <Grid item xs={12} md={0}>
            <Card sx={{ minHeight: "calc(100vh - 30vh)" }}>
              <ProgressTable onOpenLogs={handleExecLogsOpen} filters={formats}/>
            </Card>
          </Grid>
        </Grid>
      </ArgonBox>
      <Footer />
    </DashboardLayout>
  );
}


export default ExecutionProgress;
