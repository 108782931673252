

import { forwardRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Argon Dashboard 2 PRO MUI components
import ArgonTypography from "components/ArgonTypography";

// Custom styles for ArgonProgressLabel
import ArgonProgressRoot from "components/ArgonProgress/ArgonProgressRoot";

const ArgonProgressLabel = forwardRef(({ variant, color, value, label, ...rest }, ref) => (
  <>
    {label && (
      <ArgonTypography variant="button" fontWeight="medium" color="text">
        {label}
      </ArgonTypography>
    )}
    <ArgonProgressRoot
      {...rest}
      ref={ref}
      variant="determinate"
      value={value}
      ownerState={{ color, value, variant }}
    />
  </>
));

// Setting default values for the props of ArgonProgressLabel
ArgonProgressLabel.defaultProps = {
  variant: "contained",
  color: "info",
  value: 0,
  label: false,
};

// Typechecking props for the ArgonProgressLabel
ArgonProgressLabel.propTypes = {
  variant: PropTypes.oneOf(["contained", "gradient"]),
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  value: PropTypes.number,
  label: PropTypes.string,
};

export default ArgonProgressLabel;
