import apiClient from "api/BackendApi";

const getAllIntegration = (user) => apiClient.post("/be/account/getAllIntegrationByUser", {"user": user});

const addIntegration = (user,integration,integrationInfo) => apiClient.post("/be/account/addIntegration", {"user": user, "integration": integration, "integrationInfo": integrationInfo});

const connectToGitLab = (urlRepository,tokenCloudRepository) => apiClient.post("/be/account/connectToGitLab", {"url": urlRepository,"token": tokenCloudRepository});

const getBranchesGitLabByRepositoryId = (urlRepository,tokenCloudRepository,id) => apiClient.post("/be/account/getBranchesGitLabByRepositoryId", {"url": urlRepository,"token": tokenCloudRepository,"id": id});

const connectToGitHub = (urlRepository,tokenCloudRepository) => apiClient.post("/be/account/connectToGitHub", {"url": urlRepository,"token": tokenCloudRepository});

const getReposGitHub = (urlRepository,tokenCloudRepository,org,project) => apiClient.post("/be/account/getReposGitHub", {"url": urlRepository,"token": tokenCloudRepository,"org": org,"project": project});

const getBranchReposGitHub = (urlRepository,tokenCloudRepository,owner,project) => apiClient.post("/be/account/getBranchReposGitHub", {"url": urlRepository,"token": tokenCloudRepository,"owner": owner,"project": project});

const connectToJira = (urlRepository,emailCLoudRepository,tokenCloudRepository) => apiClient.post("/be/account/connectToJira", {"url": urlRepository,"email": emailCLoudRepository,"token": tokenCloudRepository});

const connectToAdo = (tokenCloudRepository, orgRepository) => apiClient.post("/be/account/connectToAdo", {"token": tokenCloudRepository,"org": orgRepository});

export {
  getAllIntegration,
  addIntegration,
  connectToGitLab,
  getBranchesGitLabByRepositoryId,
  connectToGitHub,
  getReposGitHub,
  getBranchReposGitHub,
  connectToJira,
  connectToAdo
};
