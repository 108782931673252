import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

import Grid from "@mui/material/Grid";
import useAuth from "utils/useAuth";
import ArgonBox from "components/ArgonBox";
import Header from "components/Header";
import Footer from "components/Footer";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import Card from "@mui/material/Card";
import {CircularProgress, Stack} from "@mui/material";
import { Module } from "api/BackendApi/Module";
import ModuleCard from "./components/ModuleCard";

function ModuleManagement({navbarRefresher}) {
  const isLoggedIn = useAuth().ensureLoggedIn();
  if (!isLoggedIn) {
    return <Navigate replace to="/sign-in" />;
  }

  const [modules, setModules] = useState(null);

  const loadModules = async () => {
    let response = await Module.getModulesAll();
      setModules(response.data.modules);
  }

  useEffect(() => {
    loadModules().then(r => console.log("Modules loaded"));
  }, []);

  if (modules == null) {
            return <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{ width: 1, height: '400px' }}
            >
              <CircularProgress size={64} />
            </Stack>;
  }

  return (
    <DashboardLayout>
      <Header />
      <ArgonBox mt={5} mb={3}>
        <Card sx={{ minHeight: "calc(100vh - 30vh)", display: "flex" }}>
          <Grid container p={2} spacing={2} direction="row" justifyContent="flex-start" alignItems="flex-start">
            <Grid item key={modules[0]._id} lg={3} md={4} xs={12}>
              <ModuleCard module={modules[0]} navbarRefresher={navbarRefresher}/>
            </Grid>
            {modules.map(
                (module) => {
                  if(module.name.includes("Dashboard") || module.name.includes("Module Management") || module.name.includes("TAF Settings")) {
                    return;
                  }
                  return <Grid item key={module._id} lg={3} md={4} xs={12}>
                            <ModuleCard module={module} navbarRefresher={navbarRefresher}/>

                          </Grid>;
                }
            )}
          </Grid>
        </Card>
      </ArgonBox>
      <Footer />
    </DashboardLayout>
  );
}


export default ModuleManagement;
