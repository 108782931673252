import Grid from "@mui/material/Grid";
import ArgonBox from "components/ArgonBox";
import Card from "@mui/material/Card";
import { ChartsAPI } from "api/BackendApi/ChartsAPI";
import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import GaugeChart from "./GaugeChart";
import ArgonTypography from "components/ArgonTypography";

const loading = () => {
  return (<ArgonBox style={{
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)'
  }}>
    <CircularProgress size={64} />
  </ArgonBox>);
};

function GroupedGaugeChart({ name, description, fromDate, toDate, filters, onReady }) {

  const [data, setData] = useState({
    base: true,
    executed: 0,
    failed: 0,
    passed: 0,
    progress: 0,
    total_tests_count: 0,
    total_tests_executed: 0
  });

  useEffect(() => {
    const worker = async () => {
      var result = await ChartsAPI.getChartsGaugeGrouped(fromDate, toDate, filters);
      setData(result.data);
      onReady();
    };

    worker();
  }, [fromDate, toDate, filters]);

  if (data.base === true) {
    return <div />;
  }

  return <Card sx={{ paddingTop: "10px", paddingLeft: "10px", paddingRight: "10px" }}  >
    <div sx={{
      position: "absolute",
      top: 0,
      left: 5,
      padding: "10px"
    }}>
      <ArgonTypography fontWeight="bold">{name} </ArgonTypography>
      <ArgonTypography fontSize={14} sx={{ transform: "translateY(-7px)" }}>{description}</ArgonTypography>
    </div>

    <Grid container
      columns={4}
      direction="row"
      justifyContent="space-evenly"
      alignItems="center"
      sx={{ height: "190px" }}
      spacing={1}>
      <Grid item xs={1}>
        <GaugeChart
          title="Total Tests"
          description="Total Tests Count"
          percentage={100}
          value={data.total_tests_count}
        />
      </Grid>
      <Grid item xs={1}>
        <GaugeChart
          title="Executed"
          description="Executed over Total"
          tooltip={data.executed_num + " Executed over " + data.instances_num + " instances"}
          percentage={parseFloat(data.executed).toFixed(2)}
        />
      </Grid>
      <Grid item xs={1}>
        <GaugeChart
          title="Passed"
          description="Passed over Executed"
          tooltip={data.passed_num + " Passed over " + data.executed_num + " Executed instances"}
          percentage={parseFloat(data.passed).toFixed(2)}
        />
      </Grid>

    </Grid>
    <Grid container
      direction="row"
      justifyContent="space-evenly"
      alignItems="center"
      columns={4}
      sx={{ height: "190px" }}
      spacing={1}>
      <Grid item xs={1}>
        <GaugeChart
          title="Total Runs"
          description="Total Test Runs"
          value={data.total_runs}
        />
      </Grid>
      <Grid item xs={1}>
        <GaugeChart
          title="Progress"
          description="Passed over Total"
          tooltip={data.passed_num + " Passed over " + data.instances_num + " instances"}
          percentage={parseFloat(data.progress).toFixed(2)}
        />
      </Grid>
      <Grid item xs={1}>
        <GaugeChart
          title="Failed"
          description="Failed over Executed"
          percentage={parseFloat(data.failed).toFixed(2)}
          tooltip={data.failed_num + " Failed over " + data.executed_num + " Executed instances"}
          success={false}
        />
      </Grid>
    </Grid>
  </Card>;
}

export default GroupedGaugeChart;


