import { Navigate, useLocation, useNavigate } from "react-router-dom";

import { useEffect, useState } from "react";

import Grid from "@mui/material/Grid";
import useAuth from "utils/useAuth";

import ArgonBox from "components/ArgonBox";

import Footer from "components/Footer";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";


import Card from "@mui/material/Card";
import Header from "components/Header";

import { TestSchedulingAPI } from "api/BackendApi/TestScheduling";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { useMemo } from "react";
import { CircularProgress } from "@mui/material";


const loading = () => {
  return (<ArgonBox mt={2} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
    <CircularProgress size={64} />
  </ArgonBox>);
};

function TestScheduleMngmt() {
  const isLoggedIn = useAuth().ensureLoggedIn();
  if (!isLoggedIn) {
    return <Navigate replace to="/sign-in" />;
  }

  const location = useLocation();
  const navigate = useNavigate();

  const columns = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: 'Name',
        grow: true,
      },
      {
        accessorKey: 'start',
        header: 'Start',
        grow: true,
      },
      {
        accessorKey: 'options.cron',
        header: 'Cron',
        grow: true,
      }
    ],
    [],
  );

  const [data, setData] = useState(null);

  const table = useMaterialReactTable({
    columns: columns,
    data: data ?? [], //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    initialState: { density: 'compact', pagination: { pageSize: 30 } },
    enableColumnResizing: true,
    columnResizeMode: 'onEnd',
    enableDensityToggle: false,
    columnFilterDisplayMode: 'popover',
    muiPaginationProps: {
      rowsPerPageOptions: [30, 50, 100],
    },
    muiTableHeadProps: {
      sx: {
        padding: '0 !important',
      },
    },
    muiEditTextFieldProps: {
      inputProps: {
        sx: {
          width: '100% !important',
          height: '100% !important',
        },
      },
    },
    muiTableBodyCellProps: {
      sx: {
        fontSize: "0.8rem",
      },
    },
    muiTableHeadCellProps: {
      sx: {
        fontSize: "0.8rem",
        '& .Mui-TableHeadCell-Content': {
          justifyContent: 'center',
        },
      },
    },
    muiTablePaperProps: ({ table }) => ({
      //not sx
      style: {
        zIndex: table.getState().isFullScreen ? 10000 : undefined,
      },
    }),
  });

  const doWork = async () => {
    var schedulesResponse = await TestSchedulingAPI.getSchedules();
    var schedules = schedulesResponse.data.schedules;
    setData(schedules);
  };

  useEffect(() => {
    doWork();
  }, []);

  let body;
  if (data == null) {
    body = loading();
  } else {
    body = <MaterialReactTable table={table} />
  }

  return (
    <DashboardLayout>
      <Header />
      <ArgonBox mt={5} mb={3}>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={11}  >
            <Card sx={{ minHeight: "calc(100vh - 30vh)", display: "flex" }}>
              <ArgonBox pt={2} pl={2} pr={2}>
                {body}
              </ArgonBox>
            </Card>
          </Grid>
        </Grid>
      </ArgonBox>
      <Footer />
    </DashboardLayout>
  );
}

export default TestScheduleMngmt;
