import apiClient from "api/BackendApi";


const ChartsAPI = {
  getStandardStatusPie: (fromDate, toDate, configurationFilters) => apiClient.post(
    "/be/charts/standard_pie",
    {
      "fromDate": fromDate,
      "toDate": toDate,
      "filters": configurationFilters
    }
  ),
  getStatisticsStatusPie: (fromDate, toDate, configurationFilters, option) => apiClient.post(
    "/be/charts/statistics_status_pie",
    {
      "fromDate": fromDate,
      "toDate": toDate,
      "filters": configurationFilters,
      "option": option
    }
  ),
  getChartsHeatmapDailyExecFail: (configurationFilters) => apiClient.post(
    "/be/charts/heatmap/exec_fail",
    {
      "filters": configurationFilters,
    }
  ),
  getChartsHorizontalCountries: (fromDate, toDate, configurationFilters) => apiClient.post(
    "/be/charts/horizontal/countries",
    {
      "fromDate": fromDate,
      "toDate": toDate,
      "filters": configurationFilters,
    }
  ),
  getChartsRiverStatus: (configurationFilters, fromDate, toDate) => apiClient.post(
    "/be/charts/river/status",
    {
      "fromDate": fromDate,
      "toDate": toDate,
      "filters": configurationFilters,
    }
  ),
  getChartsGaugeGrouped: (fromDate, toDate, configurationFilters) => apiClient.post(
    "/be/charts/gauge/grouped",
    {
      "fromDate": fromDate,
      "toDate": toDate,
      "filters": configurationFilters,
    }
  ),
  setChartConfigEnabled: (configId, enabled) => apiClient.post(
    "/be/charts/config/set_enabled",
    {
      "config_id": configId,
      "enabled": enabled,
    }
  ),
  getChartsConfigsAllDashboards: () => apiClient.get("/be/charts/config/list"),
  getChartsConfigs: (dashboardId) => apiClient.get("/be/charts/config/list/" + dashboardId),
  setChartsConfigsOrder: (orders) => apiClient.post("/be/charts/config/order", orders),
  setChartWidth: (configId, width) => apiClient.post("/be/charts/config/chart_width", {"config_id": configId, "width": width}),
  
}

export {
  ChartsAPI
};
