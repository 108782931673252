import { Navigate, useLocation, useNavigate } from "react-router-dom";

import { useState } from "react";

import Grid from "@mui/material/Grid";
import useAuth from "utils/useAuth";

import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import Stack from "@mui/material/Stack";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from 'components/ArgonTypography';

import Footer from "components/Footer";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";


import Card from "@mui/material/Card";
import Header from "components/Header";
import CapabilitySelectionStack from 'pages/test-schedule/components/CapabilitySelectionStack';
import KanbanSummary from 'pages/test-schedule/components/KanbanSummary';
import { HUBAPI } from "api/BackendApi/HUB";

import HorizontalLinearStepper from "components/QualityStepper";
import StepperStep from "components/QualityStepper/StepperStep";
import ScheduleStep from "./components/ScheduleStep";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArgonButton from "components/ArgonButton";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';


function TestSchedule() {
  const isLoggedIn = useAuth().ensureLoggedIn();
  if (!isLoggedIn) {
    return <Navigate replace to="/sign-in" />;
  }

  const capabilityStepTitle = "Capability Selection";
  const scheduleStepTitle = "Launch Configuration";
  const summaryStepTitle = "Testing Plan Review";

  const location = useLocation();
  const navigate = useNavigate();

  const [selectedTests, setSelectedTests] = useState(location.state.tests);
  const [stepOutputValues, setStepOutputValues] = useState({});
  const [isLaunchingTests, setIsLaunchingTests] = useState(false);

  const [launchError, setLaunchError] = useState(null);

  const [launchSuccess, setLaunchSuccess] = useState(null);


  const onTestLaunched = () => {
    navigate('/execution-progress');
  };

  const runTests = async (tests, options) => {
    try {
      var clonedOptions = JSON.parse(JSON.stringify(options));
      clonedOptions.selectedFailureBehaviour = clonedOptions.selectedFailureBehaviour.value;
      clonedOptions.selectedSendMailBehaviour = clonedOptions.selectedSendMailBehaviour.value;
      clonedOptions.selectedParallelizationOption = clonedOptions.selectedParallelizationOption.value;
      clonedOptions.selectedRetryOption = clonedOptions.selectedRetryOption.value;
      if (!clonedOptions.enableRecurrence) {
        clonedOptions.cron = null;
      } else {
        clonedOptions.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      }

      setIsLaunchingTests(true);
      var response = await HUBAPI.launchTests(tests, clonedOptions);

      if (response.data.response == null || response.data.response.ok === false) {
        setLaunchError(response.data.response.error);
      } else {
        setLaunchSuccess(true);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsLaunchingTests(false); // Here we may want to keep it to true, and show a different widget, or just redirect to execution progress

    }
  };

  const onFinish = () => {
    runTests(stepOutputValues[summaryStepTitle], stepOutputValues[scheduleStepTitle])
  };

  const onOutputValueUpdate = (title, data) => {
    var values = JSON.parse(JSON.stringify(stepOutputValues)); // Ugly
    values[title] = data;
    setStepOutputValues(values);
  };

  const stepComponents = [
    <CapabilitySelectionStack
      key={0}
      title={capabilityStepTitle}
      outputValueUpdate={onOutputValueUpdate}
      startingCapabilities={stepOutputValues[capabilityStepTitle]}
    />,
    <ScheduleStep
      key={1}
      title={scheduleStepTitle}
      initialValues={stepOutputValues[scheduleStepTitle]}
      outputValueUpdate={onOutputValueUpdate}
    />,
    <KanbanSummary
      key={2}
      title={summaryStepTitle}
      outputValueUpdate={onOutputValueUpdate}
      capabilityList={stepOutputValues[capabilityStepTitle]?.cap}
      testInstances={selectedTests}
    />
  ];

  var steps = stepComponents.map(
    (e, i) => {
      return <StepperStep
        key={i}
        title={e.props.title}
        nextEnabled={stepOutputValues[e.props.title] != null}>
        {e}
      </StepperStep>;
    }
  )

  return (
    <DashboardLayout>
      <Header />
      <Dialog
        open={launchError != null}
        onClose={() => setLaunchError(null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={"md"}
        maxWidth={"md"}
      >
        <DialogTitle textAlign="center">{"Launch Error!"}</DialogTitle>
        <DialogContent>
          <ArgonTypography>{launchError}</ArgonTypography>
        </DialogContent>
      </Dialog>
      <Dialog
        open={launchSuccess != null}
        onClose={onTestLaunched}
        aria-labelledby="success-dialog-title"
        aria-describedby="success-dialog-description"
        fullWidth={"md"}
        maxWidth={"md"}
      >
        <DialogTitle textAlign="center">{"Launch Success!"}</DialogTitle>
        <DialogContent>
          <Grid
            container
            direction={"column"}
            justifyContent="center"
            alignItems="center">
            <Grid item>
              <ArgonTypography>Your test has been scheduled for execution.</ArgonTypography>
            </Grid>
            <Grid item>
              <CheckCircleIcon color="success" fontSize={"large"} />
            </Grid>
            <Grid
              container
              direction={"row"}
              justifyContent="end" alignItems="end">
              <Grid item >
                <ArgonButton color={"primary"} onClick={onTestLaunched}>Ok</ArgonButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <ArgonBox mt={5} mb={3}>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={11}  >
            <Card sx={{ minHeight: "calc(100vh - 30vh)", display: "flex" }}>
              <ArgonBox pt={2} pl={2} pr={2}>
                <HorizontalLinearStepper onFinish={onFinish} loading={isLaunchingTests} onBackExit={() => navigate(-1)}>
                  {steps}
                </HorizontalLinearStepper>
              </ArgonBox>
            </Card>
          </Grid>
        </Grid>
      </ArgonBox>
      <Footer />
    </DashboardLayout>
  );
}

export default TestSchedule;
