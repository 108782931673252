import { Stack } from "@mui/material";
import Card from "@mui/material/Card";
import { ChartsAPI } from "api/BackendApi/ChartsAPI";
import ArgonTypography from "components/ArgonTypography";
import ReactEcharts from "echarts-for-react";
import { useEffect, useState } from "react";

function FlagsHorizontalBars({ name, fromDate, toDate, filters, onReady }) {


  const [data, setData] = useState(null);

  const weatherIcons = {
    Spain: "https://raw.githubusercontent.com/lipis/flag-icons/main/flags/4x3/es.svg",
    Mexico: "https://raw.githubusercontent.com/lipis/flag-icons/main/flags/4x3/mx.svg",
    Japan: "https://raw.githubusercontent.com/lipis/flag-icons/main/flags/4x3/jp.svg"
  };

  const seriesLabel = {
    show: true
  };

  const option = {
    /*title: {
       text: 'Weather Statistics'
    },*/
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    color: [
      '#a1e8c7',
      '#e18383',
      '#f8e8a1'
    ],
    legend: {
      data: ['Passed', 'Failed', 'Not Completed']
    },
    grid: {
      left: 90,
    },
    xAxis: {
      type: 'value',
      name: 'Days',
      axisLabel: {
        formatter: '{value}'
      }
    },
    yAxis: {
      type: 'category',
      inverse: true,
      data: data == null ? [] : data["countries"],
      axisLabel: {
        formatter: function (value) {
          return '{' + value + '| }\n{value|' + value + '}';
        },
        margin: 20,
        rich: {
          value: {
            lineHeight: 30,
            align: 'center'
          },

          /*Spain: {
            height: 40,
            align: 'center',
            backgroundColor: {
              image: weatherIcons.Spain
            }
          },
          Mexico: {
            height: 40,
            align: 'center',
            backgroundColor: {
              image: weatherIcons.Mexico
            },
            shadow: {
              backgroundColor: '#992233',
              padding: 5,
              color: '#fff',
              shadowBlur: 5,
              shadowColor: '#336699',
              shadowOffsetX: 6,
              shadowOffsetY: 6
            },
          },
          Japan: {
            height: 40,
            align: 'center',
            backgroundColor: {
              image: weatherIcons.Japan
            }
          }*/
        }
      }
    },
    series: [
      {
        name: 'Passed',
        type: 'bar',
        data: data != null ? data["data"]["Passed"] : [],
        label: seriesLabel,

      },
      {
        name: 'Failed',
        type: 'bar',
        label: seriesLabel,
        data: data != null ? data["data"]["Failed"] : [],
      },
      {
        name: 'Not Completed',
        type: 'bar',
        label: seriesLabel,
        data: data != null ? data["data"]["Not Completed"] : [],
      }
    ]
  };

  useEffect(() => {
    const worker = async () => {
      var result = await ChartsAPI.getChartsHorizontalCountries(fromDate, toDate, filters);
      setData(result.data);
      onReady();
    };

    worker();
  }, [filters]);

  if (data == null) {
    return <div />;
  }

  if (data.countries.length == 0) {
    return <Card sx={{ paddingTop: "10px", paddingLeft: "10px", paddingRight: "10px" }}>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ width: 1, height: '400px' }}
      >
        <ArgonTypography
          fontWeight="bold"
          sx={{
            position: "absolute",
            top: 0,
            left: 5,
            padding: "10px"
          }}
        >
          {name}
        </ArgonTypography>
        <ArgonTypography sx={{ textAlign: "center", alignItems: "center", flexDirection: "column" }}>No Data</ArgonTypography>
      </Stack>
    </Card>;
  }

  return <Card sx={{ paddingTop: "10px", paddingLeft: "10px", paddingRight: "10px" }}>
    <ArgonTypography
      fontWeight="bold"
      sx={{
        position: "absolute",
        top: 0,
        left: 5,
        padding: "10px"
      }}
    >
      {name}
    </ArgonTypography>
    <ReactEcharts option={option} style={{ height: '400px' }} />
  </Card>;
}

export default FlagsHorizontalBars;
