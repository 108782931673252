import { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";

import Grid from "@mui/material/Grid";

import useAuth from "utils/useAuth";

import ArgonBox from "components/ArgonBox";

import Footer from "components/Footer";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";

import Header from "components/Header";

import EmptyDetails from "pages/test-execution/components/EmptyDetails";
import FolderDetails from "pages/test-execution/components/FolderDetails";
import CycleDetails from "pages/test-execution/components/CycleDetails";
import CycleTree from "pages/test-execution/components/CycleTree/CycleTree";
import ParametersDetails from "./components/ParametersDetails";


function TestExecution() {

  const isLoggedIn = useAuth().ensureLoggedIn();
  if (!isLoggedIn) {
    return <Navigate replace to="/sign-in" />;
  }

  const [selectedNodeId, setSelectedNodeId] = useState(sessionStorage.getItem("seltreenode"));
  const [treeRefreshHandle, setTreeRefreshHandle] = useState(1);
  const [showParams, setShowParams] = useState(false);
  const [selectedCycle, setSelectedCycle] = useState(null);
  const [selectedCycleTestInstances, setSelectedCycleTestInstances] = useState([]);

  const getDetailsContent = () => {
    var selectedNodeIdLocal = selectedNodeId;

    if (selectedNodeIdLocal == null) {
      selectedNodeIdLocal = sessionStorage.getItem("seltreenode");
    }

    if (selectedNodeIdLocal == null) {
      return <EmptyDetails />;
    }

    var id = selectedNodeIdLocal.split('-')[1];
    if (selectedNodeIdLocal.includes('folder-')) {
      return <FolderDetails folderId={id} />;
    } else if (selectedNodeIdLocal.includes('cycle-')) {
      return <CycleDetails
        cycleId={id}
        setShowParams={setShowParams}
        setSelectedCycle={setSelectedCycle}
        setSelectedCycleTestInstances={setSelectedCycleTestInstances}
        refreshCycleTree={() => {
          setTreeRefreshHandle(treeRefreshHandle + 1)
        }
        }
      />;
    }
  };

  return (
    <DashboardLayout>
      <Header />
      <ArgonBox mt={5} mb={3}>
        {!showParams ?
          <Grid container spacing={2}>
            <Grid item xs={12} md={3} xl={0}>
              <CycleTree
                key={treeRefreshHandle}
                onSelect={(v) => {
                  setSelectedNodeId(v);
                  if (v == null) {
                    setSelectedCycle(null);
                    setSelectedCycleTestInstances([]);
                  }
                }} />
            </Grid>
            <Grid item xs={12} md={9} xl={0} xh={5}>
              {getDetailsContent()}
            </Grid>
          </Grid>
          :
          <Grid container>
            <ParametersDetails setShowParams={setShowParams} initSlectedCycle={selectedCycle} selectedCycleTestInstances={selectedCycleTestInstances} />
          </Grid>
        }
      </ArgonBox>
      <Footer />
    </DashboardLayout>
  );

}

export default TestExecution;
