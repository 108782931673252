import React, { useState } from "react";

// Argon Dashboard 2 PRO MUI
import ControllerCard from "../../../components/Cards/ControllerCard";

import logoADO from "assets/images/small-logos/logo-azure-devops.svg";
import logoFigma from "assets/images/small-logos/logo-figma.svg";
import logoJira from "assets/images/small-logos/logo-jira.svg";
import logoAsana from "assets/images/small-logos/logo-asana.svg";
import logoGithub from "assets/images/small-logos/logo-github.svg";
import logoGitlab from "assets/images/small-logos/logo-gitlab.svg";
import logoAWS from "assets/images/small-logos/logo-aws.svg";
import logoSaucelabs from "assets/images/small-logos/logo-saucelabs.svg";
import logoBrowserstack from "assets/images/small-logos/logo-browserstack.svg";
import logoSlack from "assets/images/small-logos/logo-slack.svg";
import logoTeams from "assets/images/small-logos/logo-teams.svg";
import logoBitbucket from "assets/images/small-logos/logo-bitbucket.svg";
import logoEmail from "assets/images/small-logos/logo-email.svg";

// @mui material components
import {enqueueSnackbar} from "notistack";
import {Settings} from "../../../api/BackendApi/Settings";

function SettingCard({setting}) {
    const [active, setActive] = useState(setting.enabled);
    const [area, setArea] = useState(setting.area);
    const [name, setName] = useState(setting.setting);
    const imageMap = {
        "Azure DevOps": logoADO,
        "Figma": logoFigma,
        "Jira": logoJira,
        "Asana": logoAsana,
        "GitHub": logoGithub,
        "GitLab": logoGitlab,
        "AWS": logoAWS,
        "SauceLabs": logoSaucelabs,
        "BrowserStack": logoBrowserstack,
        "Slack": logoSlack,
        "Teams": logoTeams,
        "Bitbucket": logoBitbucket,
        "Email": logoEmail
    };

    const updateSetting = async (setting_id) => {
      try {
        await Settings.updateSetting(setting_id, !active);
        setActive(!active);
        enqueueSnackbar(!active ? 'Setting enabled!' : 'Setting disabled!',{variant:'success'});
      } catch (e) {
        console.log(e);
        enqueueSnackbar('Error during the enabling of the setting',{variant:'error'})
      }
    };

    return (
        <ControllerCard
            icon={<img src={imageMap[name]} style={{width: "3vw", height: "3vw"}}  alt="logo"/>}
            title={name}
            description={(active) ? "Active" : "Disabled"}
            state={active}
            color={"dark"}
            onChange={(event) => {
                updateSetting(setting._id)
            }}
        />
    );
}

export default SettingCard;