import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {keyframes} from "@emotion/react";

import Grid from "@mui/material/Grid";

import Stack from "@mui/material/Stack";
import ArgonBox from "components/ArgonBox";

import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';

import { HUBAPI } from "api/BackendApi/HUB";
import ArgonTypography from 'components/ArgonTypography';
import {CircularProgress, IconButton, tooltipClasses} from '@mui/material';
import { Refresh } from "@mui/icons-material";


import colors from "assets/theme/base/colors";
import Box from "@mui/material/Box";
import {styled} from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";

const { primary } = colors;


const loading = () => {
  return <Stack direction='row' justifyContent='center'><CircularProgress /></Stack>;
};

function CapabilitySelectionStack({ title, outputValueUpdate, startingCapabilities }) {
  const [capabilities, setCapabilities] = useState(null);

  const [loadingCapabilitiesError, setLoadingCapabilitiesError] = useState(null);
  const [loadingCapabilities, setLoadingCapabilities] = useState(true);

  const [selectedCapabilities, setSelectedCapabilities] = useState(startingCapabilities ?? { index: [], cap: [] });

  useEffect(() => {
    loadCapabilities();
  }, []);

  const loadCapabilities = async () => {
    try {
      setLoadingCapabilities(true);
      var resp = await HUBAPI.getCapabilities()
      setCapabilities(resp['data']['capabilities']);
    } catch (e) {
      setLoadingCapabilitiesError(e);
    } finally {
      setLoadingCapabilities(false);
    };
  };

  const onCardClick = (index) => {
    var newValue = selectedCapabilities?.index ?? [];
    if (newValue.includes(index)) {
      newValue = newValue.filter(item => item !== index)
    } else {
      newValue.push(index)
    }

    var output = null;

    if (newValue.length > 0) {
      output = {
        index: newValue,
        cap: newValue.map(c => capabilities[c])
      }
    }

    setSelectedCapabilities(output)
    outputValueUpdate(title, output)
  };

  const capabilitiesToCards = () => {
    return capabilities.map((c, i) => {
      const isSelected = selectedCapabilities?.index.includes(i) ?? false;
      const selectedStyle = isSelected ? { outline: 1, outlineColor: "primary.main" } : {};
      const selectedStyleBg = isSelected ? {backgroundColor: primary.light} : {}
      const shapeStyles = { bgcolor: 'primary.main', width: 20, height: 20 };
      const shapeCircleStyles = { borderRadius: '50%' };
      const blink = keyframes` from { opacity: 0; } to { opacity: 1; }`;

      const NoMaxWidthTooltip = styled(({ className, ...props }) => (
          <Tooltip {...props} classes={{ popper: className }} />
      ))({
        [`& .${tooltipClasses.tooltip}`]: {
          maxWidth: 'none',
        },
      });

      return <Card key={i} sx={{ ...selectedStyle }} >
        <CardActionArea onClick={() => onCardClick(i)} >
          <CardContent sx={selectedStyleBg}>
            <ArgonBox >
              <Grid container direction="column">
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={2}>
                      {c.available === true &&
                          <NoMaxWidthTooltip title="Available" placement="top">
                            <Card component="span" title={"OK"} sx={{ ...shapeStyles, ...shapeCircleStyles, backgroundColor: '#1aae6f', animation: `${blink} 1s linear infinite` }}/>
                          </NoMaxWidthTooltip>
                      }
                      {c.available === false &&
                          <NoMaxWidthTooltip title="On Working" placement="top">
                            <Card component="span" title={"OK"} sx={{ ...shapeStyles, ...shapeCircleStyles, backgroundColor: '#ae1a26', animation: `${blink} 1s linear infinite` }}/>
                          </NoMaxWidthTooltip>
                      }
                    </Grid>
                    <Grid item xs={9}>
                    </Grid>
                    <Grid item xs={1}>
                      <ArgonBox display='flex' justifyContent='center'>
                        {c.type.toLowerCase() === "ios" && <i style={{ color: 'lightslategrey'}}>
                          <svg xmlns="http://www.w3.org/2000/svg"
                               fill="#000000"
                               width={20}
                               viewBox="-52.01 0 560.035 560.035"
                          >
                            <path d="M380.844 297.529c.787 84.752 74.349 112.955 75.164 113.314-.622 1.988-11.754 40.191-38.756 79.652-23.343 34.117-47.568 68.107-85.731 68.811-37.499.691-49.557-22.236-92.429-22.236-42.859 0-56.256 21.533-91.753 22.928-36.837 1.395-64.889-36.891-88.424-70.883-48.093-69.53-84.846-196.475-35.496-282.165 24.516-42.554 68.328-69.501 115.882-70.192 36.173-.69 70.315 24.336 92.429 24.336 22.1 0 63.59-30.096 107.208-25.676 18.26.76 69.517 7.376 102.429 55.552-2.652 1.644-61.159 35.704-60.523 106.559M310.369 89.418C329.926 65.745 343.089 32.79 339.498 0 311.308 1.133 277.22 18.785 257 42.445c-18.121 20.952-33.991 54.487-29.709 86.628 31.421 2.431 63.52-15.967 83.078-39.655"/>
                          </svg>
                        </i>}
                        {c.type.toLowerCase() === "android" && <i style={{ color: 'lightslategrey'}}>
                          <svg xmlns="http://www.w3.org/2000/svg"
                               fill="#000000"
                               width={20}
                               viewBox="-52.01 0 560.035 560.035"
                          >
                            <g xmlns="http://www.w3.org/2000/svg">
                              <path d="M76.774,179.141c-9.529,0-17.614,3.323-24.26,9.969c-6.646,6.646-9.97,14.621-9.97,23.929v142.914    c0,9.541,3.323,17.619,9.97,24.266c6.646,6.646,14.731,9.97,24.26,9.97c9.522,0,17.558-3.323,24.101-9.97    c6.53-6.646,9.804-14.725,9.804-24.266V213.039c0-9.309-3.323-17.283-9.97-23.929C94.062,182.464,86.082,179.141,76.774,179.141z"/>
                              <path d="M351.972,50.847L375.57,7.315c1.549-2.882,0.998-5.092-1.658-6.646c-2.883-1.34-5.098-0.661-6.646,1.989l-23.928,43.88    c-21.055-9.309-43.324-13.972-66.807-13.972c-23.488,0-45.759,4.664-66.806,13.972l-23.929-43.88    c-1.555-2.65-3.77-3.323-6.646-1.989c-2.662,1.561-3.213,3.764-1.658,6.646l23.599,43.532    c-23.929,12.203-42.987,29.198-57.167,51.022c-14.18,21.836-21.273,45.698-21.273,71.628h307.426    c0-25.924-7.094-49.787-21.273-71.628C394.623,80.045,375.675,63.05,351.972,50.847z M215.539,114.165    c-2.552,2.558-5.6,3.831-9.143,3.831c-3.55,0-6.536-1.273-8.972-3.831c-2.436-2.546-3.654-5.582-3.654-9.137    c0-3.543,1.218-6.585,3.654-9.137c2.436-2.546,5.429-3.819,8.972-3.819s6.591,1.273,9.143,3.819    c2.546,2.558,3.825,5.594,3.825,9.137C219.357,108.577,218.079,111.619,215.539,114.165z M355.625,114.165    c-2.441,2.558-5.434,3.831-8.971,3.831c-3.551,0-6.598-1.273-9.145-3.831c-2.551-2.546-3.824-5.582-3.824-9.137    c0-3.543,1.273-6.585,3.824-9.137c2.547-2.546,5.594-3.819,9.145-3.819c3.543,0,6.529,1.273,8.971,3.819    c2.438,2.558,3.654,5.594,3.654,9.137C359.279,108.577,358.062,111.619,355.625,114.165z"/>
                              <path d="M123.971,406.804c0,10.202,3.543,18.838,10.63,25.925c7.093,7.087,15.729,10.63,25.924,10.63h24.596l0.337,75.454    c0,9.528,3.323,17.619,9.969,24.266s14.627,9.97,23.929,9.97c9.523,0,17.613-3.323,24.26-9.97s9.97-14.737,9.97-24.266v-75.447    h45.864v75.447c0,9.528,3.322,17.619,9.969,24.266s14.73,9.97,24.26,9.97c9.523,0,17.613-3.323,24.26-9.97    s9.969-14.737,9.969-24.266v-75.447h24.928c9.969,0,18.494-3.544,25.594-10.631c7.086-7.087,10.631-15.723,10.631-25.924V185.45    H123.971V406.804z"/>
                              <path d="M476.275,179.141c-9.309,0-17.283,3.274-23.93,9.804c-6.646,6.542-9.969,14.578-9.969,24.094v142.914    c0,9.541,3.322,17.619,9.969,24.266s14.627,9.97,23.93,9.97c9.523,0,17.613-3.323,24.26-9.97s9.969-14.725,9.969-24.266V213.039    c0-9.517-3.322-17.552-9.969-24.094C493.888,182.415,485.798,179.141,476.275,179.141z"/>
                            </g>
                          </svg>
                        </i>}
                        {c.type.toLowerCase() === "web" && <i style={{ color: 'lightslategrey'}}>
                          <svg xmlns="http://www.w3.org/2000/svg"
                               fill="#000000"
                               width={20}
                               viewBox="-52.01 0 560.035 560.035"
                          >
                            <g xmlns="http://www.w3.org/2000/svg">
                              <path className="st0" d="M486.504,25.496H25.496C11.438,25.496,0,36.934,0,50.992v410.016c0,14.059,11.438,25.496,25.496,25.496   h461.008c14.058,0,25.496-11.437,25.496-25.496V50.992C512,36.934,500.562,25.496,486.504,25.496z M424.585,77.008   c15.806,0,28.618,12.813,28.618,28.618c0,15.806-12.812,28.618-28.618,28.618c-15.805,0-28.618-12.812-28.618-28.618   C395.968,89.821,408.78,77.008,424.585,77.008z M334.049,77.008c15.805,0,28.618,12.813,28.618,28.618   c0,15.806-12.813,28.618-28.618,28.618c-15.805,0-28.618-12.812-28.618-28.618C305.431,89.821,318.244,77.008,334.049,77.008z    M243.512,77.008c15.806,0,28.618,12.813,28.618,28.618c0,15.806-12.812,28.618-28.618,28.618   c-15.805,0-28.618-12.812-28.618-28.618C214.894,89.821,227.707,77.008,243.512,77.008z M469.333,443.838H42.667V174.829h426.666   V443.838z"/>
                              <rect x="87.415" y="219.756" className="st0" width="226.341" height="45.61"/>
                              <rect x="87.415" y="311.333" className="st0" width="337.17" height="45.61"/>
                            </g>
                          </svg>
                        </i>}
                        {c.type.toLowerCase() === "software" && <i style={{ color: 'lightslategrey'}}>
                          <svg xmlns="http://www.w3.org/2000/svg"
                               fill="#000000"
                               width={20}
                               viewBox="-52.01 0 560.035 560.035"
                          >
                            <path d="M314.906,315.318c0-11.236-1.508-22.095-4.071-32.542l35.631-28.98l-30.689-53.156l-42.892,16.357    c-15.686-15.059-34.936-26.38-56.395-32.593l-7.275-45.287h-61.379l-7.273,45.287c-21.46,6.214-40.708,17.534-56.396,32.593    l-42.892-16.358l-30.689,53.156l35.632,28.981c-2.562,10.446-4.07,21.305-4.07,32.541c0,11.236,1.508,22.095,4.07,32.541    l-35.632,28.983l30.689,53.156l42.892-16.358c15.688,15.058,34.936,26.378,56.396,32.593l7.273,45.287h61.379l7.274-45.287    c21.46-6.214,40.71-17.535,56.396-32.593l42.892,16.358l30.689-53.156l-35.631-28.982    C313.398,337.413,314.906,326.554,314.906,315.318z M178.526,394.456c-43.708,0-79.138-35.431-79.138-79.138    c0-43.708,35.431-79.139,79.138-79.139c43.708,0,79.139,35.431,79.139,79.139S222.234,394.456,178.526,394.456z"/>
                            <path d="M460.688,113.021c0-7.207-0.967-14.173-2.61-20.873l22.855-18.59l-19.685-34.095l-27.512,10.492    c-10.062-9.659-22.409-16.921-36.174-20.906L392.895,0h-39.37l-4.666,29.049c-13.764,3.986-26.112,11.247-36.174,20.906    l-27.511-10.492l-19.686,34.095l22.856,18.59c-1.643,6.701-2.611,13.666-2.611,20.873c0,7.208,0.968,14.173,2.611,20.873    l-22.856,18.589l19.686,34.096l27.511-10.492c10.062,9.66,22.41,16.921,36.174,20.906l4.666,29.049h39.37l4.666-29.049    c13.765-3.986,26.113-11.247,36.174-20.906l27.512,10.492l19.685-34.096l-22.855-18.589    C459.721,127.194,460.688,120.229,460.688,113.021z M373.21,163.784c-28.036,0-50.763-22.727-50.763-50.762    c0-28.034,22.727-50.762,50.763-50.762c28.034,0,50.762,22.727,50.762,50.762C423.972,141.057,401.245,163.784,373.21,163.784z"/>
                          </svg>
                        </i>}
                      </ArgonBox>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} textAlign="center">
                  <ArgonTypography variant="subtitle2">{c.name}</ArgonTypography>
                </Grid>
                <Divider mt={0} style={{ width: '100%', height: '5px', margin: '0.2rem' }} />
                <Grid item xs={12}>
                  <ArgonBox display='flex' justifyContent='center'>
                    <ArgonBox component="img" src={c.image} alt={c.name} width="70%" sx={{ width: "7vw", height: "7vw", margin: "25px" }} />
                  </ArgonBox>
                </Grid>
                <Grid item xs={12}>
                  <ArgonBox display='flex' justifyContent='center'>
                    <ArgonTypography variant="Caption">{c.version!=="Unknown" ? "Version " + c.version : "Version Unavailable"}</ArgonTypography>
                  </ArgonBox>
                </Grid>
              </Grid>
            </ArgonBox>
          </CardContent>
        </CardActionArea>
      </Card>
    }
    );
  };

  if (loadingCapabilities) {
    return loading();
  }

  if (loadingCapabilitiesError != null) {
    return <ArgonBox
      key={2}
      display="flex"
      justifyContent="center"
      alignItems="center">
      <ArgonTypography textAlign="center">{`Cannot load capabilities: ${loadingCapabilitiesError.message}`}</ArgonTypography>
      <IconButton onClick={loadCapabilities}><Refresh></Refresh></IconButton>
    </ArgonBox>;
  }

  return <Stack
    direction='row'
    spacing={2}
    useFlexGap
    flexWrap="wrap"
    sx={{marginBottom: '20px'}}
    justifyContent="space-between"
  >
    {capabilitiesToCards()}
  </Stack>;
}

CapabilitySelectionStack.propTypes = {
  title: PropTypes.string,
  outputValueUpdate: PropTypes.func,
  startingCapabilities: PropTypes.object
};



export default CapabilitySelectionStack;