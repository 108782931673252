import React, {useEffect, useState} from "react";

import ArgonBox from "../../../components/ArgonBox";
import ArgonTypography from "../../../components/ArgonTypography";

import {ExpandMore, Info, Visibility, VisibilityOff} from "@mui/icons-material";
import Collapse from "@mui/material/Collapse";
import CardContent from "@mui/material/CardContent";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {Grid, Card, CardHeader, CircularProgress, InputAdornment, tooltipClasses} from "@mui/material";

// Images
import logoADO from "assets/images/small-logos/logo-azure-devops.svg";
import {enqueueSnackbar} from "notistack";
import ArgonSelect from "../../../components/ArgonSelect";
import ArgonInput from "../../../components/ArgonInput";
import ArgonButton from "../../../components/ArgonButton";
import {LoadingButton} from "@mui/lab";
import DataTable from "../../../components/DataTable";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import ErrorIcon from "@mui/icons-material/Error";
import {styled} from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import {connectToAdo, connectToJira} from "../../../api/BackendApi/Account";
import {getTicketsAdo} from "../../../api/BackendApi/microservices/ManagementTool";

export function ADOTool({userIntegrations, setManagementSend, ado, expandedDialog, module, otherInfo}) {

	const [check, setCheck] = useState(false);
	const [checkError, setCheckError] = useState(false);
	const [checkSuccess, setCheckSuccess] = useState(false);

	const [expanded, setExpanded] = useState(expandedDialog);
	const [showPassword, setShowPassword] = React.useState(false);

	const handleClickShowPassword = () => setShowPassword((show) => !show);
	const handleMouseDownPassword = (event) => {event.preventDefault();};

	const [token, setToken] = useState("");
	const [org, setOrg] = useState("");
	const [projectDefault, setProjectDefault] = useState(null);
	const [projectSelectedAdo, setProjectSelectedAdo] = useState(null);

	const [titleIssue, setTitleIssue] = useState("");
	const [descriptionIssue, setDescriptionIssue] = useState(null);

	const [ticketAdo, setTicketAdo] = useState(null);

	useEffect(() => {

		if(otherInfo !== null){
			setTitleIssue("[BUG] " +otherInfo.testNameSelected);
		}

		for (let integration of userIntegrations) {
			if (integration.integration === "Ado"){
				setToken(integration.token);
				setOrg(integration.org);
				if(integration.project && integration.project !== ""){
					setProjectDefault(integration.project);
				}
			}
		}
	}, []);

	const loading = () => {
		return (<ArgonBox mt={5}
						  style={{ display: "flex", justifyContent: "center", alignItems: "center"}}>
			<CircularProgress size={64} />
		</ArgonBox>);
	};

	const handleExpandClick = () => {
		setExpanded(!expanded);
	};

	const handleSelectProjectAdo = async (e) => {
		if (e !== null) {
			setProjectSelectedAdo(e.value)
			if(module === "Generative"){

				setTicketAdo(null)

				let infoToGetUserStoryOnAdo = {
					"token": token,
					"organization": org,
					"project": e.label,
				}

				try{
					let get_user_story = await getTicketsAdo(infoToGetUserStoryOnAdo)

					if(get_user_story.data.count > 0){
						setTicketAdo(get_user_story.data.value)
					}else{
						setTicketAdo([])
					}
				}catch (e){
					setTicketAdo([])

					console.log(e)
					enqueueSnackbar("Error!", {variant: "error"});
				}
			}
		} else
			setProjectSelectedAdo(null)
	};

	const handleChangeValue = (val,name) => {
		switch (name) {
			case "token":
				setToken(val);
				break;
			case "org":
				setOrg(val);
				break;
			default:
				break;
		}
	}

	const handleConnectIntegration = async () => {
		try{
			setCheck(true)
			setCheckError(false)
			const response = await connectToAdo(token,org);
			setCheck(false)
			if (response.status === 200) {
				if(projectDefault!==null){
					ado.setProjectsADO(response.data.value.filter(project => project.name === projectDefault))
				}else{
					ado.setProjectsADO(response.data.value)
				}
				setCheckSuccess(true)
				ado.setConnectedADO(true)
			}else{
				setCheckError(true)
				setCheckSuccess(false)
			}
		}catch (err) {
			setCheck(false)
			setCheckError(true)
			setCheckSuccess(false)

			console.log(err)
		}
	}

	const NoMaxWidthTooltip = styled(({ className, ...props }) => (
		<Tooltip {...props} classes={{ popper: className }} />
	))({
		[`& .${tooltipClasses.tooltip}`]: {
			maxWidth: 'none',
		},
	});

	return (
		<Card>
			<CardHeader
				action={
					<ExpandMore
						expand={expanded}
						onClick={handleExpandClick}
						aria-expanded={expanded}
						aria-label="show more"
					>
						<ExpandMoreIcon />
					</ExpandMore>
				}
				title={
					<Grid container justifyContent="center" alignItems="center" mt={1} mb={1}>
						<Grid item >
							<ArgonBox mr={1}>
								<img src={logoADO} style={{width: "2vw", height: "2vw"}} alt="logoADO"/>
							</ArgonBox>
						</Grid>
						<Grid item >
							<ArgonBox>
								<ArgonTypography component="label" variant="h5" fontWeight="bold">
									Azure DevOps
								</ArgonTypography>
							</ArgonBox>
						</Grid>
					</Grid>
				}
			/>
			<Collapse in={expanded} timeout="auto" unmountOnExit>
				{
					ado.connectedADO ?
						<CardContent>
							<ArgonBox mb={projectSelectedAdo === null ? 25 : 3}>
								<ArgonSelect
									size={"large"}
									placeholder={"Select Project"}
									onChange={(e) => handleSelectProjectAdo(e)}
									options={ado.projectsADO.map((project) => {
										return ({
											"label": project.name,
											"value": project.id
										})
									})}
								/>
							</ArgonBox>
							{module === "TestResult" ?
								<>
									{projectSelectedAdo!==null ?
										<>
											<ArgonBox>
												<Grid container>
													<Grid item xs={12} md={12} sx={{textAlign: "center"}}>
														<ArgonTypography component="label" variant="h5" fontWeight="bold">
															CREATE ISSUE
														</ArgonTypography>
													</Grid>
													<Grid item xs={12} md={12} mb={3}>
														<ArgonTypography fontSize={14}>Title</ArgonTypography>
														<ArgonBox>
															<ArgonInput
																placeholder="[BUG] Issue Title"
																size="medium"
																value={titleIssue==null ? "" : titleIssue}
																onChange={(e) => setTitleIssue(e.target.value !== "" ? e.target.value : null)}
															/>
														</ArgonBox>
													</Grid>
													<Grid item xs={12} md={12} mb={3}>
														<ArgonTypography fontSize={14}>Description</ArgonTypography>
														<ArgonBox>
															<ArgonInput
																multiline rows={5}
																placeholder="Insert Description"
																size="medium"
																value={descriptionIssue==null ? "" : descriptionIssue}
																onChange={(e) => setDescriptionIssue(e.target.value !== "" ? e.target.value : null)}
															/>
														</ArgonBox>
													</Grid>
													<Grid item mt={2} xs={12} md={12} sx={{textAlign: "center"}}>
														{!check ?
															<ArgonButton
																variant="contained"
																color="primary"
																size="large"
																onClick={sendIssueToJira}
																disabled={titleIssue==null || descriptionIssue==null}
															>
																Create Issue
															</ArgonButton>
															:
															<LoadingButton color="primary" variant="contained" size="large" loading>Create Issue</LoadingButton>
														}
													</Grid>
												</Grid>
											</ArgonBox>
										</>
										:
										<></>
									}
								</>
								:
								<>
									{projectSelectedAdo!==null ?
										<>
											<ArgonBox>
												<Grid container>
													<Grid item xs={12} md={12} sx={{textAlign: "center"}}>
														<ArgonTypography component="label" variant="h5" fontWeight="bold">
															USER STORY
														</ArgonTypography>
													</Grid>
													<Grid item xs={12} md={12} mb={3}>
														{ticketAdo!==null ?
															<>
																{ticketAdo.length > 0 ?
																	<>
																		<Grid item xs={12} md={12} sx={{textAlign: "center"}}>
																			<DataTable
																				canSearch={true}
																				entriesPerPage={false}
																				enableSelection={true}
																				onSelectionChange={(rows) => {

																					ado.adoFiles.current = rows.filter(v => v !== undefined).map(r => r?.values);

																					if(ado.adoFiles.current.length > 0){
																						setManagementSend(true);
																					}else {
																						setManagementSend(false);
																					}
																				}}
																				table={{
																					columns: [
																						{ Header: "KEY", accessor: "ticket_key", width: "2vw" },
																						{ Header: "NAME", accessor: "ticket_name", maxWidth: "40vw" },
																					],
																					rows: ticketAdo?.filter((ticket) => ticket.fields['System.WorkItemType'] === 'User Story')
																						.map((ticket) => {
																							return {
																								"ticket_id": ticket.id,
																								"ticket_key": <Link href={ticket.url} target="_blank" underline="always">{ticket.id}</Link>,
																								"ticket_name": ticket.fields['System.Title'],
																								"ticket_description": ticket.fields['System.Description']
																							}
																					})
																				}}
																			/>
																		</Grid>

																	</>
																	:
																	<>
																		<Grid item xs={12} md={12} mt={5} sx={{textAlign: "center"}}>
																			<ArgonTypography component="label" variant="h6" fontWeight="bold">
																				NO DATA
																			</ArgonTypography>
																		</Grid>
																	</>
																}
															</>
															:
															loading()
														}
													</Grid>
												</Grid>
											</ArgonBox>
										</>
										:
										<></>
									}
								</>
							}
						</CardContent>
						:
						<CardContent>
							<ArgonBox
								bgColor={"grey-100"}
								borderRadius="lg"
								display="flex"
								justifyContent="space-between"
								alignItems={{ xs: "flex-start", sm: "center" }}
								flexDirection={{ xs: "column", sm: "row" }}
								my={3}
								py={1}
								pl={{ xs: 1, sm: 2 }}
								pr={1}
							>
								<ArgonTypography variant="button" fontWeight="medium" color="text">
									Token
									<NoMaxWidthTooltip title="https://confluence.atlassian.com/enterprise/using-personal-access-tokens-1026032365.html" placement="top">
										<Icon>
											<Info></Info>
										</Icon>
									</NoMaxWidthTooltip >
								</ArgonTypography>
								<ArgonBox width={{ xs: "100%", sm: "50%", md: "50%" }} mt={{ xs: 1, sm: 0 }}>
									<ArgonInput
										size="medium"
										type={showPassword ? 'text' : 'password'}
										placeholder="Add your Token"
										autoComplete="off"
										value={token}
										endAdornment={
											<InputAdornment position="end">
												<IconButton
													aria-label="toggle password visibility"
													onClick={handleClickShowPassword}
													onMouseDown={handleMouseDownPassword}
													edge="end"
												>
													{showPassword ? <VisibilityOff /> : <Visibility />}
												</IconButton>
											</InputAdornment>
										}
										onChange={(e) => {handleChangeValue(e.target.value!=="" ? e.target.value : "","token")}}/>
								</ArgonBox>
							</ArgonBox>
							<ArgonBox
								bgColor={"grey-100"}
								borderRadius="lg"
								display="flex"
								justifyContent="space-between"
								alignItems={{ xs: "flex-start", sm: "center" }}
								flexDirection={{ xs: "column", sm: "row" }}
								my={3}
								py={1}
								pl={{ xs: 1, sm: 2 }}
								pr={1}
								mt={0}
							>
								<ArgonTypography variant="button" fontWeight="medium" color="text">
									Organization
								</ArgonTypography>
								<ArgonBox width={{ xs: "100%", sm: "50%", md: "50%" }} mt={{ xs: 1, sm: 0 }}>
									<ArgonInput
										type="text"
										size="medium"
										placeholder="Organization Name"
										value={org}
										onChange={(e) => {handleChangeValue(e.target.value!=="" ? e.target.value : "","org")}}/>
								</ArgonBox>
							</ArgonBox>
							{!check ?
								<ArgonButton fullWidth variant="gradient" color="primary" sx={{ mr: 2 }} size="medium"
											 onClick={handleConnectIntegration} disabled={token==="" || org===""}>
									Connect
								</ArgonButton>
								:
								<LoadingButton fullWidth color="primary" loading variant="contained"  sx={{ mr: 2 }}  size="medium">Connect</LoadingButton>
							}
							{checkError &&
								<>
									{checkSuccess ?

										<></>
										:
										<ArgonTypography variant="button" fontWeight="light"  sx={{ mr: 2, color: 'red' }} color="text">
											<ErrorIcon fontSize="inherit"/> Connection Refused!
										</ArgonTypography>
									}
								</>
							}
						</CardContent>
				}
			</Collapse>
		</Card>
	);
} 


