// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 PRO MUI contexts
import Gitlab from "./Account/Gitlab";
import Jira from "./Account/Jira";
import AzureDevops from "./Account/AzureDevops";
import Figma from "./Account/Figma";
import GitHub from "./Account/GitHub";

function Accounts({user,settings,account,setAccount}) {

    return (
        <Card id="accounts">
            <ArgonBox p={3} lineHeight={1}>
                <ArgonBox mb={1}>
                    <ArgonTypography variant="h5">Accounts</ArgonTypography>
                </ArgonBox>
                <ArgonTypography variant="button" color="text" fontWeight="regular">
                    Here you can setup and manage your integration settings.
                </ArgonTypography>
            </ArgonBox>
            <ArgonBox pt={2} pb={3} px={3}>
                {settings.find(s => s.setting === "GitLab" && s.enabled) && <Gitlab user={user} account={account} setAccount={setAccount}/>}
                {settings.find(s => s.setting === "GitHub" && s.enabled) && <GitHub user={user} account={account} setAccount={setAccount}/>}
                {settings.find(s => s.setting === "Jira" && s.enabled) && <Jira user={user} account={account} setAccount={setAccount}/>}
                {settings.find(s => s.setting === "Azure DevOps" && s.enabled) && <AzureDevops user={user} account={account} setAccount={setAccount}/>}
                {settings.find(s => s.setting === "Figma" && s.enabled) && <Figma />}
            </ArgonBox>
        </Card>
    );
}

export default Accounts;
