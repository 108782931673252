import React, { useState, useEffect, useRef } from "react";
import {
	MaterialReactTable,
	useMaterialReactTable,
	MRT_EditActionButtons,
	createMRTColumnHelper
} from 'material-react-table';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "../../../components/ArgonBox";
import ArgonTypography from "../../../components/ArgonTypography";

// @mui material components
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {
	Box,
	Button,
	TableRow,
	TableHead,
	TableContainer,
	TableCell,
	TableBody,
	Table,
	Paper,
	IconButton,
	Grid,
	Card,
	DialogTitle,
	DialogActions,
	DialogContent,
	CardHeader, CardActions, Backdrop, Modal,
} from "@mui/material";

//import api backend
import {
	addRequirementFile,
	downloadFile
} from "../../../api/BackendApi/microservices/GenerativeTest";

//custom components
import { queryClient } from "../GenerativeTest";
import { DeleteModal } from "./DeleteModal";
import CreateNewTestCase from "../../url-website/components/CreateNewTestCase";
import CreateNewTestCaseAdvanced from "./CreateNewTestCaseAdvanced";
import {enqueueSnackbar} from "notistack";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import {createTheme, styled, ThemeProvider} from "@mui/material/styles";
import {excelExport} from "./ExcelExporter";
import ArgonButton from "../../../components/ArgonButton";
import Tooltip from "@mui/material/Tooltip";
import {RefreshOutlined} from "@mui/icons-material";


export const renderTestEditDialog = ({ table, row, internalEditComponents }) => {
	return (
		//TODO: make a inner editable table for steps
		<>
			<DialogTitle variant="h3">Edit Test</DialogTitle>
			<DialogContent
				sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}
			>
				{internalEditComponents} {/* or render custom edit components here */}
			</DialogContent>
			<DialogActions>
				<MRT_EditActionButtons variant="text" table={table} row={row} />
			</DialogActions>
		</>
	);
}

const theme = createTheme({
	components: {
		// Name of the component
		MuiCardHeader: {
			styleOverrides: {
				// Name of the slot
				title: {
					// Some CSS
					fontSize: '1rem',
				},
				subheader: {
					// Some CSS
					fontSize: '0.9rem',
				},
			},
		},
	},
});

const ExpandMore = styled((props) => {
	const { expand, ...other } = props;
	return <IconButton {...other} />;
})(({ theme, expand }) => ({
	transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
	marginLeft: 'auto',
	transition: theme.transitions.create('transform', {
		duration: theme.transitions.duration.shortest,
	}),
}));


export function GenerativeTestTable({ user, tests, setTests, setLoading, setUploadProgress, setGenerateSend, setGenerativeTestError, dropzoneFiles, jiraFiles, generateType }) {

	const rowVirtualizerInstanceRef = useRef(null);
	const [sorting, setSorting] = useState([]);
	const [rowSelection, setRowSelection] = useState([]);
	const [openModalDeleteTest, setOpenModalDeleteTest] = useState(false);
	const [columns, setColumns] = useState([]);
	const [isTestBook, setIsTestBook] = useState(false);
	const [runId, setRunId] = useState(0);
	const [runType, setRunType] = useState("");
	const [downloadScriptsGenerated, setDownloadScriptsGenerated] = useState(false);

	const [expanded, setExpanded] = React.useState(false);

	const [openModalBack, setOpenModalBack] = useState(false);

	const columnHelper = createMRTColumnHelper();

	// * USE EFFECTS
	useEffect(() => {
		//scroll to the top of the table when the sorting changes
		try {
			rowVirtualizerInstanceRef.current?.scrollToIndex?.(0);
		} catch (error) {
			console.error(error);
		}
	}, [sorting]);

	useEffect(() => {
		refetch();
	}, []);

	const handleExpandClick = () => {
		setExpanded(!expanded);
	};

	const handleBack = () => {
		setGenerateSend(false);
		setTests([]);
	};

	const handleCloseModal = () => setOpenModalBack(false);

	const handleOpenModal = () => {
		setOpenModalBack(true);
	}

	//READ
	const {
		data: dataTests = [],
		isRefetching,
		refetch,
	} = useGetTests();

	const handleDownloadFeature = async (rows) => {

		let data = {
			"features_id": rows.map((row) => {
								return row.id;
							}),
			"run_id": runId,
		}

		const downloadResponse = await downloadFile("gherkintest",data)

		const blob = new Blob([downloadResponse.data], { type: 'application/zip' });
		const url = window.URL.createObjectURL(blob);
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', 'featureFiles.zip'); // Set the desired filename and extension
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	}

	const handleDownloadScriptsGenerated = async () => {

		let data = {
			"run_id": runId,
		}

		const downloadResponse = await downloadFile("scripts", data)

		const blob = new Blob([downloadResponse.data], {type: 'application/zip'});
		const url = window.URL.createObjectURL(blob);
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', 'scripts.zip'); // Set the desired filename and extension
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	}

	//READ hook
	function useGetTests() {
		return useQuery({
			queryKey: ['test'],
			queryFn: async () => {
				if (dropzoneFiles.current.length > 0) {

					setLoading(true);
					setUploadProgress(0);

					const file = dropzoneFiles.current[0];
					// create a new FormData object and append the file to it
					const formData = new FormData();

					//Adding files to the formdata
					formData.append("files", file);
					formData.append("type", generateType);

					setUploadProgress(60);
					try {
						const generativeResponse = await addRequirementFile(formData)
						if (generativeResponse.status === 200) {

							const dataResponse = generativeResponse.data;
							console.log("response:", dataResponse);
							setUploadProgress(100);

							if (dataResponse.run_id) {
								setRunId(dataResponse.run_id)
								setRunType(dataResponse.step_name)
							}

							if (dataResponse.functional_testbook) {
								setIsTestBook(true);
								const dataGenerativeTable = await Promise.all(
									dataResponse.functional_testbook.map(async (item) => {
										return {
											id: item.test_id,
											test_name: item.test_name,
											area: item.area,
											steps: item.steps
										};
									})
								)

								const columns_functional_testBook = [
									columnHelper.accessor('id', {
										header: 'ID',
										size: 10,
										muiTableHeadCellProps: {
											align: 'left',
										},
									}),
									columnHelper.accessor('test_name', {
										header: 'Test Name',
										size: 150,
										muiTableHeadCellProps: {
											align: 'center',
										},
										muiTableBodyCellProps: {
											align: 'center',
										},

									}),
									columnHelper.accessor('area', {
										header: 'Area',
										size: 100,
										muiTableHeadCellProps: {
											align: 'center',
										},
										muiTableBodyCellProps: {
											align: 'center',
										},
									}),
								];

								setTests(dataGenerativeTable);
								setColumns(columns_functional_testBook);
								setGenerativeTestError(false);
								setUploadProgress(-1);
								setLoading(false);
								return Promise.resolve(dataGenerativeTable);

							} else if (dataResponse.functional_gherkintest) {
								setIsTestBook(false);
								const dataGenerativeTable = await Promise.all(
									dataResponse.functional_gherkintest.map(async (item) => {
										return {
											id: item.feature_id,
											feature_name: item.feature_name,
											scenarios: item.scenarios
										};
									})
								)

								const columns_functional_gherkinTest = [
									columnHelper.accessor('id', {
										header: 'ID',
										size: 10,
										muiTableHeadCellProps: {
											align: 'left',
										},
									}),
									columnHelper.accessor('feature_name', {
										header: 'Feature Name',
										size: 350,
										muiTableHeadCellProps: {
											align: 'left',
										},
										muiTableBodyCellProps: {
											align: 'left',
										},

									}),
								];

								setTests(dataGenerativeTable);
								setColumns(columns_functional_gherkinTest);
								setGenerativeTestError(false);
								setUploadProgress(-1);
								setLoading(false);
								return Promise.resolve(dataGenerativeTable);
							}
						} else {
							console.log("Error!");
							setUploadProgress(-1);
							setLoading(false);
							setGenerativeTestError(true);
							setTests([]);
							return Promise.resolve([]);
						}
					}catch (e) {
						console.log("Error!");
						setUploadProgress(-1);
						setLoading(false);
						setGenerativeTestError(true);
						setTests([]);
						return Promise.resolve([]);
					}
				}
				else {
					return Promise.resolve([]);
				}
			},
			refetchOnWindowFocus: false,
		});
	}

	//UPDATE hook
	function useUpdateTest() {
		const queryClient = useQueryClient();
		return useMutation({
			mutationFn: async (test) => {
				try{
					const columns_functional_gherkinTest = [
						columnHelper.accessor('id', {
							header: 'ID',
							size: 10,
							muiTableHeadCellProps: {
								align: 'left',
							},
						}),
						columnHelper.accessor('feature_name', {
							header: 'Feature Name',
							size: 350,
							muiTableHeadCellProps: {
								align: 'left',
							},
							muiTableBodyCellProps: {
								align: 'left',
							},

						}),
					];

					setTests(test);
					setIsTestBook(false);
					setColumns(columns_functional_gherkinTest);
				}catch (e) {
					console.log(e);
					enqueueSnackbar('Error!',{variant:'error'})
					return Promise.reject();
				}
			},
			//client side optimistic update
			onMutate: (newTest) => {
				queryClient.setQueryData(['test'], newTest);
			},
		});
	}

	//call UPDATE hook
	const { mutateAsync: updateTest, isPending: isUpdatingTest } =
		useUpdateTest();

	//UPDATE action
	const handleSaveTest = async (response) => {
		updateTest(response).then(r => {
			console.log("Tests updated");
		});
	};

	//DELETE
	const { mutateAsync: deleteTest, isPending: isDeletingTest
	} = useDeleteTest();

	//DELETE test action
	function actionDeleteTests(selectedTestsToDelete) {
		for (const testToDelete of selectedTestsToDelete) {
			deleteTest(testToDelete.original).then(r => {
				setTests((prevTest) => prevTest?.filter((test) => test.id !== testToDelete.id))
			});
		}
		setOpenModalDeleteTest(false);
	}

	//DELETE function
	function useDeleteTest() {
		return useMutation({
			mutationFn: async (test) => {
				setTests((oldTests) => oldTests.filter((t) => t.id !== test.id));
			},
			onMutate: (testToDelete) => {
				queryClient.setQueryData(['test'], (prevTest) => prevTest?.filter((test) => test.id !== testToDelete.id)
				);
			},
		});
	}

	const tableTests = useMaterialReactTable({
		columns,
		data: dataTests,
		createDisplayMode: 'custom',
		editDisplayMode: 'custom',
		enableRowSelection: true,
		selectAllMode: 'all',
		enableBottomToolbar: true,
		enablePagination: true,
		positionToolbarAlertBanner: 'bottom',
		muiTableContainerProps: { sx: { maxHeight: '1000px' } },
		enableFullScreenToggle: false,
		columnFilterDisplayMode: 'popover',
		onSortingChange: setSorting,
		state: {
			isSaving: isUpdatingTest || isDeletingTest,
			showProgressBars: isRefetching,
			sorting,
			rowSelection,
		},
		initialState: {
			density: 'compact', pagination: { pageSize: 25 }
		},
		muiPaginationProps: {
			rowsPerPageOptions: [25, 50, 100],
		},
		muiTableHeadProps: {
			sx: {
				display: 'table-header-group !important',
			},
		},
		muiFilterTextFieldProps: {
			sx: { m: '0.5rem 0', width: '100%' },
			variant: 'outlined',
		},
		onRowSelectionChange: setRowSelection,
		muiSelectAllCheckboxProps: {
			sx: {
				height: '1.5rem !important',
				width: '1.5rem !important'
			}
		},
		muiSelectCheckboxProps: {
			sx: {
				height: '1.5rem !important',
				width: '1.5rem !important'
			}
		},
		getRowId: (originalRow) => originalRow.id,
		onEditingRowSave: handleSaveTest,
		renderCreateRowDialogContent: renderTestEditDialog,
		//onRowSelectionChange: setRowTestSelection, //if I need to make custom actions with selected rows
		renderTopToolbarCustomActions: ({ table }) => (
			<Box
				sx={{
					display: 'flex',
					gap: '16px',
					padding: '8px',
					flexWrap: 'wrap',
				}}
			>
				{isTestBook ?
					<>
						<Button
							disabled={table.getPrePaginationRowModel().rows.length === 0}
							//export all rows, including from the next page, (still respects filtering and sorting)
							onClick={() => excelExport(table.getRowModel().rows)}
							startIcon={<FileDownloadIcon />}
						>
							Export Page
						</Button>
						<Button
							disabled={!tableTests.getSelectedRowModel().rows.length > 0}
							//only export selected rows
							onClick={() => excelExport(table.getSelectedRowModel().rows)}
							startIcon={<FileDownloadIcon />}
						>
							Export Selected
						</Button>
					</>
					:
					<>
						<Button
							disabled={table.getPrePaginationRowModel().rows.length === 0}
							//export all rows, including from the next page, (still respects filtering and sorting)
							onClick={() => handleDownloadFeature(table.getRowModel().rows)}
							startIcon={<FileDownloadIcon />}
						>
							Download Feature Files in Page
						</Button>
						<Button
							disabled={!tableTests.getSelectedRowModel().rows.length > 0}
							//only export selected rows
							onClick={() => handleDownloadFeature(table.getSelectedRowModel().rows)}
							startIcon={<FileDownloadIcon />}
						>
							Download Selected Feature Files
						</Button>
						{downloadScriptsGenerated &&
							<Button
								onClick={() => handleDownloadScriptsGenerated()}
								startIcon={<FileDownloadIcon />}
							>
								Download Source Code
							</Button>
						}

					</>
				}

			</Box>
		),
		renderDetailPanel: ({ row }) => {

			let innerRows = [];

			if(isTestBook){
				innerRows = row.original.steps || [];
			}else{
				innerRows = row.original.scenarios || [];
			}

			return (
				<>
					{isTestBook ?
					<>
						{innerRows.length > 0 ?
							<TableContainer component={Paper}>
								<Table aria-label="simple table">
									<TableHead sx={{ display: 'table-header-group !important', minWidth: 1500 }}>
										<TableRow key={"inner row header row"}>
											<TableCell>Step#</TableCell>
											<TableCell>Action</TableCell>
											<TableCell>Expected Result</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{innerRows.map((innerRow) => (
											<TableRow
												key={"row step" + innerRow.step_id}
												sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
											>
												<TableCell>{innerRow.step}</TableCell>
												<TableCell>{innerRow.action}</TableCell>
												<TableCell>{innerRow.expected_result}</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
							: <ArgonTypography>No Steps</ArgonTypography>
						}
					</>
					: <>
						{innerRows.length > 0 ?
							<>
								<Grid container spacing={2}>
								{innerRows.map((innerRow) => (
									<Grid
										key={"scenario-" + innerRow.scenario_id}
										item xs={12} sm={12} lg={4} borderRadius={4}>
										<Card>
											<ThemeProvider theme={theme}>
												<CardHeader
													title={"Scenario " + innerRow.scenario_id}
													subheader={innerRow.scenario_name}
													sx={{backgroundColor: "#ededed",paddingBottom: "0"}}
												/>
											</ThemeProvider>
											<CardActions
												disableSpacing
												sx={{backgroundColor: "#ededed"}}
											>
												<ExpandMore
													expand={expanded}
													onClick={handleExpandClick}
													aria-expanded={expanded}
													aria-label="show more"
												>
													<ExpandMoreIcon />
												</ExpandMore>
											</CardActions>
											<Collapse in={expanded} timeout="auto" unmountOnExit>
												<CardContent
													sx={{backgroundColor: "white"}}>
													{innerRow.steps.map((steps) => (
														<Typography
															key={"steps-" + steps.step_id}
															paragraph
															variant="body2"
														><code>{steps.step}</code></Typography>
													))}
												</CardContent>
											</Collapse>
										</Card>
									</Grid>
								))}
								</Grid>
							</>
							: <ArgonTypography>No Scenario</ArgonTypography>
						}
					</>}
				</>
			);
		},
	});

	return (<>
		{tests.length > 0 &&
			<ArgonBox mb={3} mt={5}>
				<Card sx={{ minHeight: "calc(100vh - 35vh)", display: "flex" }}>
					<Grid container p={5} direction="row" justifyContent="flex-end" alignItems="flex-start">
						<Grid container spacing={1}>
							<Grid item>
								{!isTestBook ?
								<CreateNewTestCase module={"generative"} setFeatureName={false} buttonDisabled={!tableTests.getSelectedRowModel().rows.length > 0} table={tableTests}/>
									:
									<></>
								}
							</Grid>
							<Grid item>
								{!isTestBook ?
									<>
									{!downloadScriptsGenerated &&
									<CreateNewTestCaseAdvanced user={user} isTestBook={isTestBook} buttonDisabled={!tableTests.getSelectedRowModel().rows.length > 0} table={tableTests} runId={runId}
															   setRunId={setRunId} runType={runType} setRunType={setRunType} setDownloadScriptsGenerated={setDownloadScriptsGenerated}
															   saveInTestRepository={true} handleSaveTest={handleSaveTest}/>
									}
									</>
									:
									<CreateNewTestCaseAdvanced user={user} isTestBook={isTestBook}
															   buttonDisabled={!tableTests.getSelectedRowModel().rows.length > 0}
															   table={tableTests} runId={runId}
															   setRunId={setRunId} runType={runType}
															   setRunType={setRunType}
															   setDownloadScriptsGenerated={setDownloadScriptsGenerated}
															   saveInTestRepository={false}
															   handleSaveTest={handleSaveTest}/>
								}

							</Grid>
							<Grid item>
								<DeleteModal openModalDeleteTest={openModalDeleteTest} setOpenModalDeleteTest={setOpenModalDeleteTest} tableTests={tableTests} actionDeleteTests={actionDeleteTests} />
							</Grid>
						</Grid>
						<Grid item md={12} xs={12} paddingTop={2}>
							<MaterialReactTable table={tableTests} />
						</Grid>
					</Grid>
				</Card>
				<ArgonBox mt={5} mb={5} ml={20} mr={20}>
					<ArgonButton fullWidth variant="contained" color="dark" onClick={() => handleOpenModal()}> Back </ArgonButton>
					<Modal open={openModalBack}
						   onClose={(event, reason) => {
							   if (reason !== 'backdropClick') {
								   handleCloseModal(event, reason);
							   }
						   }}
						   closeAfterTransition
						   slots={{ backdrop: Backdrop }}
						   slotProps={{
							   backdrop: {
								   timeout: 500,
							   },
						   }}
						   aria-labelledby="reset-tests"
						   aria-describedby="reset-tests">
						<ArgonBox mt={20} mb={20} p={1} xs={3} ml={"auto"} mr={"auto"}>
							<Grid container justifyContent="center">
								<Grid item xs={10} sm={10} lg={5} borderRadius={4}>
									<Card>
										<ArgonBox p={5}>
											<ArgonTypography variant="h3" textAlign={"center"}>
												Are you sure?
											</ArgonTypography>
											<ArgonTypography textAlign={"center"} sx={{ pt: 1 }}>
												If you go back, all tests generated will be lost.
											</ArgonTypography>
											<ArgonBox mt={4}>
												<Grid container spacing={1} justifyContent="center">
													<Grid item>
														<ArgonButton color="dark" variant="gradient" onClick={handleCloseModal}>Cancel</ArgonButton>
													</Grid>
													<Grid item>
														<ArgonButton color="error" variant="gradient" onClick={() => handleBack()}>Yes, go back!</ArgonButton>
													</Grid>
												</Grid>
											</ArgonBox>
										</ArgonBox>
									</Card>
								</Grid>
							</Grid>
						</ArgonBox>
					</Modal>
				</ArgonBox>
			</ArgonBox>
		}

	</>);
}

