import ReactEcharts from "echarts-for-react";

import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';

function GaugeChart({ title, description, percentage, value, success, tooltip }) {

  var _panelImageURLPass = require('./custom-gauge-panel.png');
  var _panelImageURLFail = require('./custom-gauge-panel-fail.png');
  
  var _animationDuration = 1000;
  var _animationDurationUpdate = 1000;
  var _animationEasingUpdate = 'quarticInOut';
  var _valOnRadianMax = 100;
  var _textMargin = 10;
  var _descriptionMargin = 30;
  var _outerRadius = 50;
  var _innerRadius = 40;
  var _marginTop = -_outerRadius/2 - 75;
  var _pointerInnerRadius = 40;
  var _insidePanelRadius = 0;
  var _currentDataIndex = 0;

  function renderItem(params, api) {
    var valOnRadian = api.value(1);
    var coords = api.coord([api.value(0), valOnRadian]);
    var polarEndRadian = coords[3];
    var imageStyle = {
      image: success == true || success == null ? _panelImageURLPass : _panelImageURLFail,
      x: params.coordSys.cx - _outerRadius,
      y: params.coordSys.cy - _outerRadius + _marginTop,
      width: _outerRadius * 2,
      height: _outerRadius * 2
    };
    return {
      type: 'group',
      children: [
        {
          type: 'image',
          style: imageStyle,
          clipPath: {
            type: 'sector',
            shape: {
              cx: params.coordSys.cx,
              cy: params.coordSys.cy + _marginTop,
              r: _outerRadius,
              r0: _innerRadius,
              startAngle: -1.5708,
              endAngle: -polarEndRadian - 1.5708,
              transition: 'endAngle',
              enterFrom: { endAngle: 0 }
            }
          }
        },
        {
          type: 'image',
          style: imageStyle,
          clipPath: {
            type: 'sector',
            shape: {
              cx: params.coordSys.cx,
              cy: params.coordSys.cy + _marginTop,
              r: _outerRadius - 6,
              r0: _innerRadius + 6,
              startAngle: 0,
              endAngle: 6.28319,
              transition: 'endAngle',
              enterFrom: { endAngle: 0 }
            }
          }
        },
        {
          type: 'circle',
          shape: {
            cx: params.coordSys.cx,
            cy: params.coordSys.cy + _marginTop,
            r: _insidePanelRadius
          },
          style: {
            fill: '#fff',
            shadowBlur: 25,
            shadowOffsetX: 0,
            shadowOffsetY: 0,
            shadowColor: 'rgba(76,107,167,0.4)'
          }
        },
        {
          type: 'text',
          extra: {
            valOnRadian: valOnRadian,
            transition: 'valOnRadian',
            enterFrom: { valOnRadian: 0 }
          },
          style: {
            text: description,
            fontSize: 10,
            x: params.coordSys.cx,
            y: params.coordSys.cy + _outerRadius + _descriptionMargin + _marginTop,
            fill: 'rgb(24,24,24)',
            align: 'middle',
            verticalAlign: 'end',
            enterFrom: { opacity: 0 }
          },
        },
        {
          type: 'text',
          extra: {
            valOnRadian: valOnRadian,
            transition: 'valOnRadian',
            enterFrom: { valOnRadian: 0 }
          },
          style: {
            text: title,
            fontSize: 15,
            fontWeight: 700,
            x: params.coordSys.cx,
            y: params.coordSys.cy + _outerRadius + _textMargin + _marginTop,
            fill: 'rgb(0,0,0)',
            align: 'middle',
            verticalAlign: 'end',
            enterFrom: { opacity: 0 }
          }
        },
        {
          type: 'text',
          extra: {
            valOnRadian: valOnRadian,
            transition: 'valOnRadian',
            enterFrom: { valOnRadian: 0 }
          },
          style: {
            text: value != null ? value : (percentage + "%"),
            fontSize: 18,
            fontWeight: 700,
            x: params.coordSys.cx + (value != null ? 0 :3),
            y: params.coordSys.cy + _marginTop,
            fill: 'rgb(0,0,0)',
            align: 'center',
            verticalAlign: 'center',
            enterFrom: { opacity: 0 }
          }
        }
      ]
    };
  }

  function convertToPolarPoint(renderItemParams, radius, radian) {
    return [
      Math.cos(radian) * radius + renderItemParams.coordSys.cx,
      -Math.sin(radian) * radius + renderItemParams.coordSys.cy
    ];
  }

  function makePionterPoints(renderItemParams, polarEndRadian) {
    return [
      convertToPolarPoint(renderItemParams, _outerRadius, polarEndRadian),
      convertToPolarPoint(
        renderItemParams,
        _outerRadius,
        polarEndRadian + Math.PI * 0.03
      ),
      convertToPolarPoint(renderItemParams, _pointerInnerRadius, polarEndRadian)
    ];
  }

  const option = {
    animationEasing: _animationEasingUpdate,
    animationDuration: _animationDuration,
    animationDurationUpdate: _animationDurationUpdate,
    animationEasingUpdate: _animationEasingUpdate,
    dataset: {
      source: [[1, percentage]]
    },
    tooltip: {
      show: value == null,
      formatter: function (params) {
        // Customize the content here
        // params is an object containing information about the data point
        return tooltip;
      }
    },
    angleAxis: {
      type: 'value',
      startAngle: 0,
      show: false,
      min: 0,
      max: _valOnRadianMax
    },
    radiusAxis: {
      type: 'value',
      show: false
    },
    polar: {},
    series: [
      {
        type: 'custom',
        coordinateSystem: 'polar',
        renderItem: renderItem
      }
    ]
  };

  return <ReactEcharts option={option} style={{ height: '300px' }} />;
}

export default GaugeChart;
