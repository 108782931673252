import { Navigate } from "react-router-dom";
import useAuth from "../../utils/useAuth";
import React, { useState, useEffect } from "react";
import ReactEcharts from "echarts-for-react";

// @mui material components
import { styled } from '@mui/material/styles';
import Grid from "@mui/material/Grid";
import { CardHeader, CircularProgress, Stack } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// Argon Dashboard 2 PRO MUI example components
import ArgonButton from "../../components/ArgonButton";
import ArgonBox from "../../components/ArgonBox";

// Overview page components
import Footer from "../../components/Footer";
import DashboardLayout from "../../components/LayoutContainers/DashboardLayout";
import Header from "../../components/Header";
import SelectFilters from "../dashboard/components/SelectFilters";
import BoxCapabilities from "../dashboard/components/BoxCapabilities";

import { TestConfig } from "../../api/BackendApi/TestConfiguration";
import ArgonTypography from "../../components/ArgonTypography";
import { Module } from "../../api/BackendApi/Module";
import ProductivityChart from "components/ProductivityChart";

import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

const loading = () => {
  return (<Stack
    direction="row"
    justifyContent="center"
    alignItems="center"
    sx={{ width: 1, height: '400px' }}
  >
    <CircularProgress size={64} />
  </Stack>);
};

function PerformancePage() {

  const isLoggedIn = useAuth().ensureLoggedIn();

  let initialDateStart = new Date();
  let initialDateEnd = new Date('January 01, 2024 00:00:00');

  const [expanded, setExpanded] = useState(true);
  const [moduleId, setModuleId] = useState(null);
  const [configs, setConfigs] = useState(null);

  const [chartsConfigs, setChartsConfigs] = useState(null);

  const [enabledCapabilities, setEnabledCapablities] = useState({});
  const [selectedFilters, setSelectedFilters] = useState({});
  const [dateRange, setDateRange] = useState([initialDateStart, initialDateEnd]);

  const [appliedFilters, setAppliedFilters] = useState(null);
  const [loadedCharts, setLoadedCharts] = useState([]);

  const [applyEnabled, setApplyEnabled] = useState(false);

  const [loadingCharts, setLoadingCharts] = useState([]);

  const [count, setCount] = useState(0);

  useEffect(() => {
    setLoadedCharts(null);

    async function loadDashboard() {
      let response = await Module.getModuleByName("Performance");
      setModuleId(response.data.modules._id);
      if (configs == null) {
        await loadConfigs(response.data.modules._id);
      }
    }
    loadDashboard().then(r => console.log("Filter Loaded"));
  }, [appliedFilters]);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const flipCapabilityFlag = (name) => {
    let newFlags = { ...enabledCapabilities };

    newFlags[name] = !(newFlags[name] ?? false);


    for (let [key, value] of Object.entries(newFlags)) {
      if (key !== name)
        newFlags[key] = false;
    }

    let allDisabled = Object.values(newFlags).find(f => f === true) != null;
    setApplyEnabled(allDisabled);
    setEnabledCapablities(newFlags);
  }

  const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }));

  const loadConfigs = async () => {
    let configsResponse = await TestConfig.getConfigs();
    let mappedConfigs = {};
    for (let config of configsResponse.data.configurations) {
      if (!(config.type in mappedConfigs)) {
        mappedConfigs[config.type] = [];
      }
      mappedConfigs[config.type].push(config);
    }

    mappedConfigs.Capability = mappedConfigs.Capability
        .filter(c => c.enabled)

    let defaultAppliedConfigs = {};
    for (let cap of mappedConfigs.Capability) {
      defaultAppliedConfigs[cap.name] = false;
    }

    setEnabledCapablities(defaultAppliedConfigs);
    setConfigs(mappedConfigs);
  };

  const onFilterChanged = (name, value) => {
    let newFilters = { ...selectedFilters };
    if (value == null) {
      delete newFilters[name];
    } else {
      newFilters[name] = value;
    }

    setSelectedFilters(newFilters);
  };

  const onDateChanged = (newDateRange) => {
    setDateRange(newDateRange);
  };


  if (!isLoggedIn) {
    return <Navigate replace to="/sign-in" />;
  }

  const getFiltersHeader = () => {
    return <>
      <CardHeader title="Filter"
        action={
          <ExpandMore expand={expanded} onClick={handleExpandClick} aria-expanded={expanded} aria-label="show more">
            <ExpandMoreIcon />
          </ExpandMore>
        }
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <SelectFilters
            dashboardId={moduleId}
            fromDate={dateRange[0]}
            toDate={dateRange[1]}
            filters={configs}
            onChange={onFilterChanged}
            onDateChange={onDateChanged}
          />
          <BoxCapabilities capabilities={configs?.Capability} flagsMap={enabledCapabilities} onChange={flipCapabilityFlag} />
          <ArgonButton
            disabled={!applyEnabled}
            onClick={() => setAppliedFilters({ "enabledCapabilities": enabledCapabilities, "selectedFilters": selectedFilters, "dateRange": dateRange })}
            variant="gradient"
            color="info"
            fullWidth>
            Apply
          </ArgonButton>
        </CardContent>
      </Collapse>
    </>;
  };

  let option1 = {
    title: {
      text: 'Response Time Vs Request',
      left: 'center',
      top: '2%'
    },
    legend: {
      data: ['Successes', 'Failures',],
      top: '10%',
    },
    tooltip: {
      trigger: 'axis'
    },
    grid: {
      top: '20%',
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    toolbox: {
      feature: {
        saveAsImage: {}
      }
    },
    xAxis: {
      name: 'Global number of requests per second',
      min: 0,
      nameLocation: 'middle',
      nameGap: 35
    },
    yAxis: {
      name: 'Median Response Time in ms',
      min: 0,
      nameLocation: 'middle',
      nameGap: 35
    },
    series: [
      {
        name: 'Successes',
        symbolSize: 10,
        data: [
          [1000, 200], [2000, 250], [3000, 300], [4000, 300], [5000, 350], [6000, 400],
          [7000, 500], [8000, 550], [9000, 600], [10000, 650],
          [2011, 239], [2112, 256], [2213, 275], [2314, 285], [2415, 275],
          [2516, 264], [2618, 280], [2719, 281], [2820, 291], [2921, 309],
          [3022, 288], [3124, 313], [3225, 309], [3326, 312], [3427, 280],
          [3528, 308], [3629, 285], [3730, 297], [3831, 319], [3933, 302],
          [4034, 303], [4135, 312], [4236, 300], [4337, 304], [4438, 303],
          [4540, 332], [4641, 320], [4742, 331], [4843, 347], [4944, 347],
          [5045, 332], [5146, 338], [5247, 377], [5348, 374], [5449, 390],
          [5550, 390], [5652, 388], [5753, 401], [5854, 392], [5955, 402],
          [6056, 425], [6157, 399], [6258, 443], [6359, 433], [6461, 450],
          [6562, 475], [6663, 482], [6764, 484], [6865, 499], [6966, 494],
          [7067, 500], [7169, 512], [7270, 531], [7371, 505], [7472, 510],
          [7573, 543], [7674, 517], [7775, 554], [7876, 525], [7978, 556],
          [8079, 554], [8180, 548], [8281, 583], [8382, 551], [8483, 587],
          [8584, 581], [8685, 579], [8787, 586], [8888, 578], [8989, 614],
          [9090, 597], [9191, 610], [9292, 595], [9393, 607], [9494, 622],
          [9596, 625], [9697, 627], [9798, 637], [9899, 630], [10000, 649],
          [5454, 375], [5545, 351], [5636, 367], [5727, 387], [5818, 377], [5909, 419],
          [6000, 400], [6090, 390], [6181, 407], [6272, 411], [6363, 441], [6454, 464],
          [6545, 468], [6636, 477], [6727, 459], [6818, 506], [6909, 499], [7000, 529],
          [7090, 502], [7181, 502], [7272, 530], [7363, 491], [7454, 510], [7545, 517],
          [7636, 556], [7727, 537], [7818, 538], [7909, 562], [8000, 523], [8090, 544],
          [8181, 552], [8272, 592], [8363, 592], [8454, 553], [8545, 578], [8636, 605],
          [8727, 565], [8818, 571], [8909, 582], [9000, 570], [9090, 597], [9181, 604],
          [9272, 587], [9363, 603], [9454, 594], [9545, 633], [9636, 650], [9727, 633],
          [9818, 650], [9909, 630], [10000, 633]
        ],
        type: 'scatter',
        itemStyle: {
          color: 'green'
        }
      },
      {
        name: 'Failures',
        symbolSize: 10,
        data:
          // Dati dei punti rossi
          [[7546.18, 1000], [8712.19, 1200], [6232.42, 1400], [9926.52, 1600], [9432.88, 2000], [9378.55, 2500], [8025.70, 3000], [7923.95, 4000], [7098.77, 5000],
          [7388.62, 699.59], [9005.00, 2027.98], [9612.00, 1261.11], [8893.38, 2763.08], [9703.74, 4328.5], [8064.90, 1349.87], [6628.24, 5003.02], [6637.73, 3202.67],
          [7024.37, 3110.33], [6321.62, 4308.72], [7371.83, 565], [6922.40, 571], [6182.29, 582], [7427.94, 570], [9966.13, 597], [7932.62, 604], [9859.39, 587],
          [6741.75, 603], [8285.79, 594], [9474.61, 633], [6578.49, 650], [8039.10, 633], [7576.24, 650], [9917.68, 630], [6134.54, 633]],
        type: 'scatter',
        itemStyle: {
          color: 'red'
        }
      }
    ]
  };

  let option2 =
      {
        title: {
          text: 'Codes Per Second',
          left: 'center',
          top: '2%'
        },
        tooltip: {
          trigger: 'axis'
        },
        grid: {
          top: '20%',
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          name: 'Elapsed Time (granularity: 1 min)',
          nameLocation: 'middle',
          nameGap: 35,
          data: ['16:33', '16:34', '16:35', '16:36', '16:37', '16:38', '16:39', '16:40', '16:41', '16:42', '16:43']
        },
        yAxis: {
          type: 'value',
          name: 'Number of hits / sec',
          nameLocation: 'middle',
          nameGap: 35
        },
        series: [
          {
            name: '200 OK',
            type: 'line',
            data: [0, 203, 278, 300, 302, 308, 270, 310, 303, 289, 0]
          }
        ]
      };

  let option3 =
      {
        title: {
          text: ' Codes Per Second',
          left: 'center',
          top: '2%'
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          top: '10%',
          data: ['200 OK','302 Redirect', '500 Internal Server Error', '404 Not Found']
        },
        grid: {
          top: '20%',
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          name: 'Elapsed Time (granularity: 1 min)',
          nameLocation: 'middle',
          nameGap: 35,
          data: ['16:33', '16:34', '16:35', '16:36', '16:37', '16:38', '16:39', '16:40', '16:41', '16:42', '16:43']
        },
        yAxis:{
          type: 'value',
          name: 'Number of responses / sec',
          nameLocation: 'middle',
          nameGap: 35
        },
        series: [
          {
            name: '200 OK',
            type: 'line',
            data: [120, 132, 101, 134, 90, 230, 210, 301, 300, 303, 320, 313]
          },
          {
            name: '302 Redirect',
            type: 'line',
            data: [60, 70, 43, 64, 30, 114, 90, 121, 164, 151, 140]
          },
          {
            name: '404 Not Found',
            type: 'line',
            data: [0,0,0, 3, 15, 1, 0, 0, 0, 0, 0 ]
          }, {
            name: '500 Internal Server Error',
            type: 'line',
            data: [0,0,0, 48, 70, 10, 12, 0, 0, 0,0]
          }
        ]
      };

  let option4 = {
    title: {
      text: ' Response Time Percentiles Over Time (successful responses)',
      left: 'center',
      top: '2%'
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        label: {
          backgroundColor: '#6a7985'
        }
      }
    },
    legend: {
      top: '10%',
      data: ['90th percentile', '95th percentile', '99th percentile', 'Max', 'Median', 'Min']
    },
    toolbox: {
      feature: {
        saveAsImage: {}
      }
    },
    grid: {
      top: '20%',
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: [
      {
        type: 'category',
        boundaryGap: false,
        name: 'Elapsed Time (granularity: 1 min)',
        nameLocation: 'middle',
        nameGap: 35,
        data: ['16:33', '16:34', '16:35', '16:36', '16:37', '16:38', '16:39', '16:40', '16:41', '16:42', '16:43']
      }
    ],
    yAxis: [
      {
        type: 'value',
        name: 'Response Time in ms',
        nameLocation: 'middle',
        nameGap: 35
      }
    ],
    series: [
      {
        name: 'Min',
        type: 'line',
        stack: 'Total',
        areaStyle: {},
        emphasis: {
          focus: 'series'
        },
        data: [120, 132, 101, 134, 90, 230, 210, 200, 180, 175, 130]
      },
      {
        name: 'Median',
        type: 'line',
        stack: 'Total',
        areaStyle: {},
        emphasis: {
          focus: 'series'
        },
        data: [150, 232, 201, 154, 190, 330, 310, 200, 180, 190, 210]
      },
      {
        name: '90th percentile',
        type: 'line',
        stack: 'Total',
        areaStyle: {},
        emphasis: {
          focus: 'series'
        },
        data: [150, 232, 201, 154, 190, 330, 310, 200, 180, 190, 210]
      },
      {
        name: '95th percentile',
        type: 'line',
        stack: 'Total',
        areaStyle: {},
        emphasis: {
          focus: 'series'
        },
        data: [320, 332, 301, 334, 390, 330, 320, 310, 200, 180, 190, 210]
      },
      {
        name: '99th percentile',
        type: 'line',
        stack: 'Total',
        areaStyle: {},
        emphasis: {
          focus: 'series'
        },
        data: [820, 800, 1051, 934, 300, 800, 1057, 802, 323, 734, 752]
      },
      {
        name: 'Max',
        type: 'line',
        stack: 'Total',
        areaStyle: {},
        emphasis: {
          focus: 'series'
        },
        data: [820, 800, 1300, 934, 300, 1330, 1057, 802, 323, 734, 752]
      }
    ]
  };

  let option5 = {
    title: {
      text: 'Response Times Over Time',
      left: 'center',
      top: '2%'
    },
    tooltip: {
      trigger: 'axis'
    },
    legend: {
      top: '10%',
      data: ['API_01', 'API_02', 'API_03', 'API_04', 'API_05']
    },
    grid: {
      top: '20%',
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    toolbox: {
      feature: {
        saveAsImage: {}
      }
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      name: 'Elapsed Time (granularity: 1 min)',
      nameLocation: 'middle',
      nameGap: 35,
      data: ['16:33', '16:34', '16:35', '16:36', '16:37', '16:38', '16:39', '16:40', '16:41', '16:42', '16:43']
    },
    yAxis: {
      type: 'value',
      name: 'Average response time in ms',
      nameLocation: 'middle',
      nameGap: 35
    },
    series: [
      {
        name: 'API_01',
        type: 'line',
        stack: 'Total',
        data: [120, 132, 101, 134, 90, 230, 210, 180, 300, 405, 600]
      },
      {
        name: 'API_02',
        type: 'line',
        stack: 'Total',
        data: [10, 20, 5, 30, 0, 100, 130, 40, 0, 10, 50]
      },
      {
        name: 'API_03',
        type: 'line',
        stack: 'Total',
        data: [10, 10, 5, 15, 10, 0, 5, 10, 0, 10, 50]
      },
      {
        name: 'API_04',
        type: 'line',
        stack: 'Total',
        data: [10, 10, 5, 15, 20, 10, 5, 10, 0, 10, 50]
      },
      {
        name: 'API_05',
        type: 'line',
        stack: 'Total',
        data: [120, 132, 101, 134, 90, 230, 210, 180, 300, 405, 600]
      }
    ]
  };

  return (
    <DashboardLayout>
      <Header />
      <ArgonBox mt={10}>
        <Card>
          <Grid container p={1}>
            <Grid item xs={12} textAlign="center">
              <ArgonTypography variant="h2">Performance</ArgonTypography>
            </Grid>
          </Grid>
        </Card>
      </ArgonBox>
      <ArgonBox mt={5}>
        <Grid container mt={5} spacing={2}>
          <Grid item xs={12}>
            <Card>
              {configs == null ? loading() : getFiltersHeader()}
            </Card>
          </Grid>
          <Grid item xs={4}>
            <ProductivityChart name="Response Time (Median)" details="1,01 s less than " />
          </Grid>
          <Grid item xs={4}>
            <ProductivityChart name="Response Time (95th Percentile)" details="1,23 s less than "/>
          </Grid>
          <Grid item xs={4}>
            <ProductivityChart name="Response Time (90th Percentile)" details="1,12 s less than  "/>
          </Grid>
          <Grid item xs={12} mt={2}>
            <Card>
              <ReactEcharts option={option4} style={{ height: '400px' }} />
            </Card>
          </Grid>
          <Grid item xs={6} mt={2}>
            <Card>
              <ReactEcharts option={option5} style={{ height: '400px' }} />
            </Card>
          </Grid>
          <Grid item xs={6} mt={2}>
            <Card>
              <ReactEcharts option={option1} style={{ height: '400px' }} />
            </Card>
          </Grid>
          <Grid item xs={6} mt={2}>
            <Card>
              <ReactEcharts option={option2} style={{ height: '400px' }} />
            </Card>
          </Grid>
          <Grid item xs={6} mt={2}>
            <Card>
              <ReactEcharts option={option3} style={{ height: '400px' }} />
            </Card>
          </Grid>

        </Grid>
      </ArgonBox>
      <Footer />
    </DashboardLayout >
  );
}


export default PerformancePage;
