import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

import Grid from "@mui/material/Grid";
import useAuth from "utils/useAuth";

import ArgonBox from "components/ArgonBox";
import Header from "components/Header";

import Footer from "components/Footer";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import SettingCard from "./components/SettingCard";
import Card from "@mui/material/Card";
import {CardHeader, CircularProgress, Divider, Stack} from "@mui/material";
import ArgonTypography from "../../components/ArgonTypography";
import {Settings} from "../../api/BackendApi/Settings";
import {enqueueSnackbar} from "notistack";
import UploadLogo from "./components/UploadLogo";
import IconButton from "@mui/material/IconButton";
import {useArgonController} from "../../context";
import CardContent from "@mui/material/CardContent";
import FeatureCard from "./components/FeatureCard";

function TafSettings() {

    const isLoggedIn = useAuth().ensureLoggedIn();
    if (!isLoggedIn) {
        return <Navigate replace to="/sign-in" />;
    }

    const [managementTools, setManagementTools] = useState([]);
    const [dvcs, setDvcs] = useState([]);
    const [deviceFarm, setDeviceFarm] = useState([]);
    const [notificationServices, setNotificationServices] = useState([]);
    const [colorSettings, setColorSettings] = useState([]);
    const [featureSettings, setFeatureSettings] = useState([]);

    const colors = ["primary", "dark", "info", "success", "warning", "error"];
    const [controller] = useArgonController();
    const { templateColor } = controller;

    const loadModules = async () => {
        let response = await Settings.getSettingsAll();
        if(response.data.settings.length === 0) {
            enqueueSnackbar("No settings found",{variant:'error'});
        }else {
            let managementToolsTmp = [];
            let dvcsTmp = [];
            let deviceFarmTmp = [];
            let notificationServicesTmp = [];

            let colorSettingsTmp = [];
            let featureSettingsTmp = [];

            for (let setting of response.data.settings) {
                if (setting.area === "Management Tools") {
                    managementToolsTmp.push(setting);
                }
                if (setting.area === "DVCS") {
                    dvcsTmp.push(setting);
                }
                if (setting.area === "Device Farm") {
                    deviceFarmTmp.push(setting);
                }
                if (setting.area === "Notification Services") {
                    notificationServicesTmp.push(setting);
                }
                if (setting.area === "Color") {
                    colorSettingsTmp.push(setting);
                }
                if (setting.area === "Features") {
                    featureSettingsTmp.push(setting);
                }
            }
            setManagementTools(managementToolsTmp);
            setDvcs(dvcsTmp);
            setDeviceFarm(deviceFarmTmp);
            setNotificationServices(notificationServicesTmp);
            setColorSettings(colorSettingsTmp);
            setFeatureSettings(featureSettingsTmp);
        }
    }

    useEffect(() => {
        loadModules().then(r => console.log("Settings loaded"));
    }, []);

    let body = <Stack
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                          sx={{ width: 1, height: '400px' }}
                      >
                        <CircularProgress size={64} />
                      </Stack>

    let circularProgress = <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ width: 1, height: '400px' }}
    >
        <CircularProgress size={64} />
    </Stack>;

    if (isLoggedIn) {
        body = (
            <Grid container p={2} direction="row" >
                <Grid item md={12} xs={12}>
                    <ArgonTypography variant="h3" textAlign="center"> Settings </ArgonTypography>
                </Grid>
                <Grid item md={12} xs={12} p={3}>
                    <Grid container direction="row" spacing={3}>
                        <Grid item md={3} xs={3}>
                            <Card>
                                <CardHeader
                                    title="Color Settings (To be released soon)"
                                />
                                <CardContent>
                                    <Grid container direction="row">
                                        {colorSettings?.length === 0 ?
                                            circularProgress
                                            :
                                            colorSettings?.map(
                                                (colorSettings) => {
                                                    return <Grid key={colorSettings._id} item md={12} xs={12}>
                                                                <ArgonBox component="form" role="form">
                                                                    <ArgonTypography variant="h6" sx={{ fontWeight : "bold" }}>{colorSettings.setting} Color</ArgonTypography>
                                                                    <ArgonBox mb={0.5}>
                                                                        {colors.map((color) => (
                                                                            <IconButton
                                                                                key={color}
                                                                                sx={({ borders: { borderWidth }, palette: { white, dark }, transitions }) => ({
                                                                                    width: "24px",
                                                                                    height: "24px",
                                                                                    padding: 0,
                                                                                    border: `${borderWidth[1]} solid ${white.main}`,
                                                                                    borderColor: templateColor === color && dark.main,
                                                                                    transition: transitions.create("border-color", {
                                                                                        easing: transitions.easing.sharp,
                                                                                        duration: transitions.duration.shorter,
                                                                                    }),
                                                                                    backgroundImage: ({ functions: { linearGradient }, palette: { gradients } }) =>
                                                                                        linearGradient(gradients[color].main, gradients[color].state),

                                                                                    "&:not(:last-child)": {
                                                                                        mr: 1,
                                                                                    },

                                                                                    "&:hover, &:focus, &:active": {
                                                                                        borderColor: dark.main,
                                                                                    },
                                                                                })}
                                                                                //onClick={() => setSidenavColor(dispatch, color)}
                                                                            />
                                                                        ))}
                                                                    </ArgonBox>
                                                                </ArgonBox>
                                                            </Grid>
                                                }
                                            )}
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item key={featureSettings._id} md={5} xs={5}>
                            <Card>
                                <CardHeader
                                    title="Features"
                                    sx={{ pb: 0 }}
                                />
                                <CardContent p={0}>
                                    {featureSettings?.length === 0 ?
                                        circularProgress
                                        :
                                        featureSettings?.map(
                                        (featureSettings) => {
                                            return  <FeatureCard key={featureSettings._id} setting={featureSettings}/>
                                        }
                                    )}
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item md={4} xs={4}>
                            <UploadLogo/>
                        </Grid>
                    </Grid>
                </Grid>
                <Divider sx={{ width: '100%', height: '5px', margin: '0.2rem' }} />
                <Grid item md={6} xs={6} textAlign="center" pt={3}>
                    <ArgonTypography variant="h3"> Management Tools </ArgonTypography>
                </Grid>
                <Grid item md={6} xs={6} textAlign="center" pt={3}>
                    <ArgonTypography  variant="h3"> Distributed Version Control Systems </ArgonTypography>
                </Grid>
                <Grid item md={6} xs={6}>
                    <Grid container p={3} direction="row" spacing={3}>
                        {managementTools?.length === 0 ?
                            circularProgress
                            :
                        managementTools?.map(
                            (managementTool) => {
                                return  <Grid item key={managementTool._id} md={6} xs={6}>
                                            <SettingCard key={managementTool._id} setting={managementTool}/>
                                        </Grid>;
                            }
                        )}
                    </Grid>
                </Grid>
                <Grid item md={6} xs={6}>
                    <Grid container p={3} direction="row" spacing={3}>
                        {dvcs?.length === 0 ?
                            circularProgress
                            :
                        dvcs?.map(
                            (dvcs) => {
                                return  <Grid item key={dvcs._id} md={6} xs={6}>
                                            <SettingCard key={dvcs._id} setting={dvcs}/>
                                        </Grid>;
                            }
                        )}
                    </Grid>
                </Grid>
                <Divider sx={{ width: '100%', height: '5px', margin: '0.2rem' }} />
                <Grid item md={6} xs={6} textAlign="center" pt={3}>
                    <ArgonTypography  variant="h3"> Device Farm </ArgonTypography>
                </Grid>
                <Grid item md={6} xs={6} textAlign="center" pt={3}>
                    <ArgonTypography  variant="h3"> Notification Services </ArgonTypography>
                </Grid>
                <Grid item md={6} xs={6}>
                    <Grid container p={3} direction="row" spacing={3}>
                        {deviceFarm?.length === 0 ?
                            circularProgress
                            :
                            deviceFarm?.map(
                            (deviceFarm) => {
                                return  <Grid item key={deviceFarm._id} md={6} xs={6}>
                                            <SettingCard key={deviceFarm._id} setting={deviceFarm}/>
                                        </Grid>;
                            }
                        )}
                    </Grid>
                </Grid>
                <Grid item md={6} xs={6}>
                    <Grid container p={3} direction="row" spacing={3}>
                        {notificationServices?.length === 0 ?
                            circularProgress
                            :
                        notificationServices?.map(
                            (notificationServices) => {
                                return  <Grid item key={notificationServices._id} md={6} xs={6}>
                                            <SettingCard key={notificationServices._id} setting={notificationServices}/>
                                        </Grid>;
                            }
                        )}
                    </Grid>
                </Grid>
            </Grid>
        )
    }

  return (
    <DashboardLayout>
      <Header />
      <ArgonBox mt={5} mb={3}>
        <Card sx={{ minHeight: "calc(100vh - 30vh)", display: "flex" }}>
            {body}
        </Card>
      </ArgonBox>
      <Footer />
    </DashboardLayout>
  );
}


export default TafSettings;
