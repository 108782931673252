import {Navigate} from "react-router-dom";
import useAuth from "utils/useAuth";
import React, {useEffect, useState} from "react";
// @mui material components
import {styled} from '@mui/material/styles';
import Grid from "@mui/material/Grid";
import {CardHeader, CircularProgress, Stack} from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// Argon Dashboard 2 PRO MUI example components
import ArgonButton from "components/ArgonButton";
import ArgonBox from "components/ArgonBox";

// Overview page components
import Footer from "components/Footer";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import Header from "components/Header";
import SelectFilters from "./components/SelectFilters";
import DailyTestsCalendar from "./components/DailyTestsCalendar";
import BoxCapabilities from "./components/BoxCapabilities";

import {ChartsAPI} from "api/BackendApi/ChartsAPI";
import {TestConfig} from "api/BackendApi/TestConfiguration";
import BarStackNormalization from "./components/BarStackNormalization";
import FlagsHorizontalBars from "./components/FlagsHorizontalBars";
import ThemeRiver from "./components/ThemeRiver";
import StatisticsPieChart from "./components/StatisticsPieChart";
import GroupedGaugeChart from "./components/GroupedGaugeChart";
import StandardPieChart from "./components/StandardPieChart";
import ArgonTypography from "../../components/ArgonTypography";


const loading = () => {
  return (<Stack
    direction="row"
    justifyContent="center"
    alignItems="center"
    sx={{ width: 1, height: '400px' }}
  >
    <CircularProgress size={64} />
  </Stack>);
};

function DashboardPage(props) {

  const isLoggedIn = useAuth().ensureLoggedIn();

  let initialDateStart = new Date();
  let initialDateEnd = new Date();
  initialDateStart.setDate(initialDateStart.getDate() - 1);

  const [expanded, setExpanded] = useState(true);
  const [configs, setConfigs] = useState(null);
  const [chartsConfigs, setChartsConfigs] = useState(null);

  const [enabledCapabilities, setEnabledCapablities] = useState({});
  const [selectedFilters, setSelectedFilters] = useState({});
  const [dateRange, setDateRange] = useState([initialDateStart, initialDateEnd]);

  const [appliedFilters, setAppliedFilters] = useState(null);
  const [loadedCharts, setLoadedCharts] = useState([]);

  const [applyEnabled, setApplyEnabled] = useState(true);

  const [loadingCharts, setLoadingCharts] = useState([]);

  const [count, setCount] = useState(0);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const flipCapabilityFlag = (name) => {
    let newFlags = { ...enabledCapabilities };
    newFlags[name] = !(newFlags[name] ?? false);
    let allDisabled = Object.values(newFlags).find(f => f === true) != null;
    setApplyEnabled(allDisabled);
    setEnabledCapablities(newFlags);
  }

  const getAppliedFilters = () => {
    let enabledConfigsMap = {};

    for (let [name, value] of Object.entries(selectedFilters)) {
      enabledConfigsMap[name] = [value];
    }

    return {
      "Capability": Object.entries(enabledCapabilities).filter(entry => entry[1]).map(entry => entry[0]),
      ...enabledConfigsMap
    }
  };

  const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }));

  const loadConfigs = async () => {
    let configsResponse = await TestConfig.getConfigs();
    let mappedConfigs = {};
    for (var config of configsResponse.data.configurations) {
      if (!(config.type in mappedConfigs)) {
        mappedConfigs[config.type] = [];
      }
      mappedConfigs[config.type].push(config);
    }

    let defaultAppliedConfigs = {};

    mappedConfigs.Capability = mappedConfigs.Capability
      .filter(c => c.enabled)
      .filter(c => {
        let dashboardModule = c.filters.find(c => c.enabled && c.module._id == props.dashboardId);
        return dashboardModule != null && dashboardModule.enabled;
      });

    for (let cap of mappedConfigs.Capability) {
      defaultAppliedConfigs[cap.name] = true;
    }

    setEnabledCapablities(defaultAppliedConfigs);
    setConfigs(mappedConfigs);
  };

  const loadChartsConfigs = async () => {
    let configsResponse = await ChartsAPI.getChartsConfigs(props.dashboardId);
    setChartsConfigs(configsResponse.data.configs);
  };

  const onFilterChanged = (name, value) => {
    let newFilters = { ...selectedFilters };
    if (value == null) {
      delete newFilters[name];
    } else {
      newFilters[name] = value;
    }

    setSelectedFilters(newFilters);
  };

  const onChartReady = (chartId) => {
    setLoadingCharts((prevState, props) => {
      let oldLoadingCharts = [...prevState];
      console.log("Chart " + chartId + " loaded");
      return oldLoadingCharts.filter(e => e !== chartId);
    });
  };

  const onChartRequested = (chartId) => {
    console.log("Requested chart " + chartId)
    setLoadingCharts((prevState, props) => {
      return [chartId, ...prevState];
    });
  };

  const onDateChanged = (newDateRange) => {
    setDateRange(newDateRange);
  };

  const statusPieChartBuilder = (width) => {
    let chartId = "status-pie-chart";
    onChartRequested(chartId);
    return <Grid item key={chartId} xs={width}>
      <StandardPieChart
        name={"Test Run Status"}
        fromDate={dateRange[0]}
        toDate={dateRange[1]}
        filters={getAppliedFilters()}
        onReady={() => onChartReady(chartId)}
      />
    </Grid>;
  };

  const riverChartBuilder = (width) => {
    let chartId = "river-chart";
    onChartRequested(chartId);
    return <Grid item key={chartId} xs={width}>
      <ThemeRiver
        name={"Hourly Runs"}
        fromDate={dateRange[0]}
        toDate={dateRange[1]}
        filters={getAppliedFilters()}
        onReady={() => onChartReady(chartId)}
      />
    </Grid>;
  };

  const calendarChartBuilder = (width) => {
    let chartId = "calendar-chart";
    onChartRequested(chartId);
    return <Grid item key={chartId} xs={width}>
      <DailyTestsCalendar
        fromDate={dateRange[0]}
        toDate={dateRange[1]}
        filters={getAppliedFilters()}
        onReady={() => onChartReady(chartId)}
      />
    </Grid>;
  };

  const gaugeGroupChartBuilder = (width) => {
    let chartId = "gauge-group-chart";
    onChartRequested(chartId);
    return <Grid item key={chartId} xs={width}>
      <GroupedGaugeChart
        name={"Cycles Overview"}
        description={"Last run for each test"}
        fromDate={dateRange[0]}
        toDate={dateRange[1]}
        filters={getAppliedFilters()}
        onReady={() => onChartReady(chartId)} />
    </Grid>;
  };

  const horizontalBarChartBuilder = (width) => {
    let chartId = "horizontal-bar-chart";
    onChartRequested(chartId);
    return <Grid item key={chartId} xs={width}>
      <FlagsHorizontalBars
        name={"Country Status"}
        fromDate={dateRange[0]}
        toDate={dateRange[1]}
        filters={getAppliedFilters()}
        onReady={() => onChartReady(chartId)}
      />
    </Grid>;
  };

  const stackBarChartBuilder = () => {
    let chartId = "stack-bar-chart";
    onChartRequested(chartId);
    return <Grid item key={chartId} xs={1}>
      <BarStackNormalization
        fromDate={dateRange[0]}
        toDate={dateRange[1]}
        filters={getAppliedFilters()}
        onReady={() => onChartReady(chartId)}
      />
    </Grid>;
  };


  const statisticsPieChartsBuilder = (width) => {
    let charts = [];
    console.log(enabledCapabilities);
    for (var [configType, configNames] of Object.entries(configs)) {
      console.log(configType)
      if (configType != "Capability") continue;
      for (var config of configNames) {
        console.log(enabledCapabilities[config.name])
        if (!enabledCapabilities[config.name]) continue;
        onChartRequested("statistics-" + config.name);
        charts.push(<Grid item key={config.name} xs={width}>
          <StatisticsPieChart
            chartId={"statistics-" + config.name}
            name={config.name + " Statistics"}
            fromDate={dateRange[0]}
            toDate={dateRange[1]}
            filters={getAppliedFilters()}
            configOption={config.name}
            configName={config.name}
            onReady={onChartReady}
          />
        </Grid>);
      }
    }

    return charts;
  };

  const chartTypeBuilderMap = {
    "Pie Status": statusPieChartBuilder,
    "Calendar Results": calendarChartBuilder,
    "Country Bars": horizontalBarChartBuilder,
    "River Status": riverChartBuilder,
    "Statistics Pie Charts": statisticsPieChartsBuilder,
    "Progress": gaugeGroupChartBuilder
  };

  const loadCharts = () => {
    if (chartsConfigs == null) return loading();

    var charts = [];

    for (var config of chartsConfigs.sort((a, b) => a.order > b.order ? 1 : -1)) {
      if (!config.enabled) continue;
      var element = chartTypeBuilderMap[config.name](config["width"] ?? 1);
      if (element instanceof Array) {
        charts.push(...element);
      } else {
        charts.push(element);
      }
    }
    return charts;
  };

  if (!isLoggedIn) {
    return <Navigate replace to="/sign-in" />;
  }

  const loadDashboard = () => {
    if (configs == null) {
      loadConfigs();
    }
    if (chartsConfigs == null) {
      loadChartsConfigs();
    }

    if (appliedFilters != null) {
      setLoadedCharts(loadCharts());
    }
  }

  useEffect(() => {
    setLoadedCharts(null);
    setApplyEnabled(true);
  }, [props.dashboardId]);

  useEffect(loadDashboard, [appliedFilters]);

  const getFiltersHeader = () => {
    return <>
      <CardHeader title="Filter"
        action={
          <ExpandMore expand={expanded} onClick={handleExpandClick} aria-expanded={expanded} aria-label="show more">
            <ExpandMoreIcon />
          </ExpandMore>
        }
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <SelectFilters
            dashboardId={props.dashboardId}
            fromDate={dateRange[0]}
            toDate={dateRange[1]}
            filters={configs}
            onChange={onFilterChanged}
            onDateChange={onDateChanged}
          />
          <BoxCapabilities capabilities={configs?.Capability} flagsMap={enabledCapabilities} onChange={flipCapabilityFlag} />
          <ArgonButton
            disabled={!applyEnabled}
            onClick={() => setAppliedFilters({ "enabledCapabilities": enabledCapabilities, "selectedFilters": selectedFilters, "dateRange": dateRange })}
            variant="gradient"
            color="info"
            fullWidth>
            Apply
          </ArgonButton>
        </CardContent>
      </Collapse>
    </>;
  };

  console.log(loadingCharts)

  return (
    <DashboardLayout>
      <Header />
      <ArgonBox mt={10}>
        <Card>
          <Grid container p={1}>
            <Grid item xs={12} textAlign="center">
              <ArgonTypography variant="h2">{props.dashbaordName}</ArgonTypography>
            </Grid>
          </Grid>
        </Card>
      </ArgonBox>
      <Grid container mt={5}>
        <Grid item xs={12}>
          <Card>
            {configs == null || chartsConfigs == null ? loading() : getFiltersHeader()}
          </Card>
        </Grid>
      </Grid>
      {loadingCharts.length > 0 && loading()}
      <Grid container mt={3} mb={3} sx={loadingCharts.length > 0 ? { 'opacity': '0' } : { 'opacity': '1', 'transition': 'opacity 1s' }}>
        <Grid item xs={12}>
          <Grid
            container
            direction="row"
            columns={4}
            spacing={2}>
            {loadedCharts}
          </Grid>
        </Grid>
      </Grid>
      <Footer />
    </DashboardLayout >
  );
}


export default DashboardPage;
