import { Stack } from "@mui/material";
import Card from "@mui/material/Card";
import { ChartsAPI } from "api/BackendApi/ChartsAPI";
import ArgonTypography from "components/ArgonTypography";
import ReactEcharts from "echarts-for-react";
import { useEffect, useState } from "react";

function StatisticsPieChart({ chartId, name, fromDate, toDate, filters, configOption, configName, onReady }) {

  const [data, setData] = useState({ "series": null, "names": [] });

  const statusToColor = (status) => {
    return { "Failed": "#e18383", "Passed": "#a1e8c7", "Not Completed": "#f8e8a1" }[status];
  };

  const calcRadius = (i) => {
    var factor = 90 / data.series?.length ?? 1;
    return [(i * (factor + 1)) + 40, (i * (factor + 1)) + (40 + factor)];
  };

  const option = {
    legend: {
      orient: 'vertical',
      right: 10,
      top: 2,
      data: data.names
    },
    tooltip: {
      trigger: 'item',
      formatter: '{a} <br/>{b} : {c} ({d}%)'
    },
    grid: {
      left: '100%',
      right: '100%',
      top: 0,
      bottom: 0
    },
    series: data.series?.map((serie, i) =>
    ({
      name: serie.name,
      type: 'pie',
      radius: calcRadius(i),
      avoidLabelOverlap: false,
      label: {
        show: false,
        position: 'center'
      },
      emphasis: {
        label: {
          show: true,
          fontSize: '30',
          fontWeight: 'bold'
        }
      },
      labelLine: {
        show: false
      },
      data: serie.data,
      animationType: 'scale',
      animationEasing: 'elasticOut',
      animationDelay: function (idx) {
        return Math.random() * 200;
      }

    }))
  };

  useEffect(() => {
    const worker = async () => {
      var result = await ChartsAPI.getStatisticsStatusPie(fromDate, toDate, filters, configOption);

      var series = [];
      var names = [];

      Object.entries(result.data.statuses).forEach(([key, value]) => {
        names = Object.keys(value);

        var mappedData = Object.entries(value).map(e => {
          return {
            "name": e[0],
            "value": e[1],
            "itemStyle": { color: statusToColor(e[0]) }
          };
        });// .sort(function (a, b) { return a.value - b.value; });

        series.push({
          "name": key,
          "data": mappedData
        });
      });

      setData({
        "series": series,
        "names": names
      });
      onReady(chartId);
    };

    worker();
  }, [fromDate, toDate, filters]);

  if (data.series == null) {
    return <div />;
  }

  if (data.series.length == 0) {
    return <Card sx={{ padding: "10px", textAlign: "center", width: 1, height: '410px' }}>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <ArgonTypography
          fontWeight="bold"
          sx={{
            position: "absolute",
            top: 0,
            left: 5,
            padding: "10px"
          }}
        >
          {name}
        </ArgonTypography>
        <ArgonTypography sx={{ textAlign: "center", top: "50%", position: "absolute" }}>
          No Data
        </ArgonTypography>
      </Stack>
    </Card >;
  }

  return <Card sx={{ paddingTop: "10px", paddingLeft: "10px", paddingRight: "10px" }}>
    <ArgonTypography
      fontWeight="bold"
      sx={{
        position: "absolute",
        top: 0,
        left: 5,
        padding: "10px"
      }}
    >
      {name}
    </ArgonTypography>
    <ReactEcharts option={option} style={{ height: '400px' }} />
  </Card>;
}

export default StatisticsPieChart;